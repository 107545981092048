@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  border: 1px solid $color-status;
  border-radius: 99px;
  border-color: $color-status;
  color: $color-status;

  @include breakpoint(md) {
    padding: 4px 20px;
    font-size: 12px;
  }

  img {
    margin-right: 16px;
    max-height: 12px;

    @include breakpoint(md) {
      margin-right: 8px;
    }
  }

  .message {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 120%;
    white-space: nowrap;

    @include breakpoint(md) {
      min-width: 0;
    }
  }
}
