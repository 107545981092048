@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  margin-bottom: 40px;

  @include breakpoint(md) {
    max-width: none;
    margin-bottom: 0px;
  }
}

.changePasswordTitle {
  color: $color-text;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  margin-bottom: 40px;

  @include breakpoint(md) {
    font-size: 14px;
    padding-bottom: 7px;
  }
}

.clientApiWrapper {
  margin-top: 30px;

  .accountWrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .tokenWrapper {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 140%;
      color: $color-text-dark;
    }

    @include breakpoint(sm) {
      padding: 0;
      display: block;
    }

    > div:first-child {
      width: 100%;
      position: relative;

      @include breakpoint(sm) {
        max-width: 100%;
      }

      svg {
        position: absolute;
        right: 4px;
        bottom: 3px;
        cursor: pointer;
      }
    }

    input {
      font-size: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 40px;
    }

    button {
      margin-left: 20px;
      font-weight: 700;
      font-size: 12px;

      @include breakpoint(sm) {
        margin-top: 10px;
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.changePassword {
  padding: 0 70px;

  @include breakpoint(sm) {
    padding: 0 20px;
  }
}

.contentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(lg) {
    display: block;
  }
}

.content {
  flex: 1;
  background-color: $color-gray-background;
  border-radius: 15px;
  padding: 40px 0;

  @include breakpoint(sm) {
    padding: 10px 0 40px 0;
  }
}
