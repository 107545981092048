@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  margin-top: 25px;
  margin-bottom: 50px;
  padding: 0 75px 0 85px;

  @include breakpoint(sm) {
    padding: 0;
    margin-bottom: 15px;
    margin-top: 0;
  }
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  &:nth-child(2n + 2) {
    background: rgba(239, 234, 232, 0.29);
  }

  @include breakpoint(md) {
    display: block;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @include breakpoint(sm) {
    display: flex;
  }
  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .column:last-child {
    text-align: right;
  }
}

.column {
  width: 15%;
  flex-shrink: 0;
  padding: 0 20px;
  color: $color-text;
  white-space: nowrap;

  &:first-child {
    flex: 1;
    @include breakpoint(sm) {
      min-width: 150px;
    }
  }
  &:nth-child(2) {
    text-align: right;

    @include breakpoint(sm) {
      text-align: center;
    }
  }
  &:nth-child(3) {
    @include breakpoint(sm) {
      text-align: right;
    }
  }
  @include breakpoint(md) {
    width: 100%;
    padding: 16px 0;
  }
  @include breakpoint(sm) {
    flex: 1;
    font-size: 10px;
  }

  &:last-of-type {
    border-right: none;
  }

  .title {
    color: $color-text-dark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;

    @include breakpoint(md) {
      margin-bottom: 0.5em;
    }
    @include breakpoint(sm) {
      font-size: 10px;
    }
  }

  .date {
    display: flex;
    align-items: center;

    .highlight {
      color: $color-secondary;
    }

    svg {
      margin-right: 12px;
      font-size: 20px;
    }
  }

  button {
    border: none;
    background: transparent;
    color: $color-secondary;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    margin-top: 6px;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}
