@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.title {
  color: $color-text;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 51px;

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    padding-top: 35px;
  }
}

.menuCategory {
  padding: 40px 24px 24px 100px;
}

.label {
  font-family: $font-poppins;
  font-weight: 300;
  font-size: 24px;
  line-height: 120%;
  color: $color-text-dark;
  margin-bottom: 16px;
}

.menuItem {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-dark;
  margin-bottom: 16px;

  svg {
    display: block;
    font-size: 20px;
    color: $color-secondary;
    margin-right: 20px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.content {
  margin: 0 auto;

  @include breakpoint(lg) {
    width: 100%;
  }
}

.wrapperComponent {
  padding: 35px 0;
  width: 100vw;

  @include breakpoint(lg) {
    display: block;
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  @include breakpoint(md) {
    padding: 0 40px;
  }

  @include breakpoint(sm) {
    padding-top: 35px;
    width: calc(100% + 50px);
    margin-left: -25px;
  }

  &.onlySlider {
    flex-direction: column;
    padding-bottom: 64px;

    .block {
      width: 100%;
      height: auto;
      border-left: none;
    }

    .title {
      background-color: transparent;
    }

    .content {
      width: 100%;
    }
  }

  .seeAlsoButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 140%;
    color: $color-text;
    padding: 36px 0 22px;

    .arrow {
      margin-left: 20px;
      color: $color-secondary;
    }

    &.active {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
}
