@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.componentWrapper {
  overflow: hidden;
  white-space: nowrap;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .variant {
    margin-right: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: $color-label;

    &:nth-child(n + 5) {
      span {
        display: none;
      }

      &::before {
        content: '...';
      }
    }

    &:nth-child(n + 6) {
      display: none;
    }
  }
}

.line {
  .variant {
    font-size: 14px;
  }

  @include breakpoint(sm) {
    max-width: 150px;

    .variant {
      &:nth-child(n + 3) {
        span {
          display: none;
        }

        &::before {
          content: '...';
        }
      }

      &:nth-child(n + 4) {
        display: none;
      }
    }
  }
}
