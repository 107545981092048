@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';

.componentWrapper {
  header {
    position: absolute;
    width: 100%;
    height: $header-height-desktop;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    z-index: 1299;
    background-color: $color-white;

    &.isHomePageHeader {
      background-color: transparent;
    }

    &.productListHeader {
      position: fixed;
    }

    &.isSimpleHeader {
      padding-top: 0px;
      -webkit-box-shadow: 0px 3px 13px 3px rgba(66, 68, 90, 0.34);
      -moz-box-shadow: 0px 3px 13px 3px rgba(66, 68, 90, 0.34);
      box-shadow: 0px 3px 13px 3px rgba(66, 68, 90, 0.34);
      height: 49px;
    }

    &.open {
      background-color: $color-white;
    }

    @include breakpoint(sm) {
      padding-top: 0;
      height: auto;
    }
  }

  .backToTop {
    position: fixed;
    bottom: 75px;
    right: 25px;
    background: $color-gray-background;
    color: $color-secondary;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    border: none;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;

    @include breakpoint(md) {
      bottom: 85px;
    }
  }

  .isHomePage {
    padding-top: 0;

    + footer {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
