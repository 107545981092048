@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;

  @include breakpoint(sm) {
    padding: 0;
    border-left: unset;
  }
}

.button {
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  width: auto;
  cursor: pointer;
  position: relative;

  &.active {
    color: $color-primary;

    .countCarts {
      color: $color-primary;
    }
    .price {
      color: $color-highlighted-position;
    }
  }

  .icon {
    font-size: 20px;
  }
}

.detailsCart {
  display: block;
  margin-right: 10px;
}

.countCarts {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 14px;
  color: $color-text;
}

.price {
  display: block;
  font-size: 13px;
  line-height: 140%;
  color: $color-text-dark;
}

.cartWrapper {
  width: 515px;
  padding: 0;
}

.alertMessageWrapper {
  display: block !important;

  > div {
    justify-content: flex-end;
  }
}

.cartAlert {
  padding: 0 12px;

  span,
  a {
    font-size: 12px;
  }

  .messageWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 !important;
  }

  .link {
    font-weight: 500;
    line-height: 140%;
    color: $color-red;
    text-decoration: none;
  }
}

.cartHeader {
  background-color: $color-primary;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  color: $color-white;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  .arrow {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}

.cartBody {
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: #e7d8d8;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a69797;
  }

  .emptyCart {
    text-align: center;
    font-weight: 300;
    color: $color-text;
    padding: 50px 16px 25px;
    font-size: 14px;

    .title {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $color-text-dark;
      line-height: 30px;
      margin-bottom: 10px;
    }
  }
}
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  gap: 16px;
  text-decoration: none;
  background-color: $color-order-bg;
  padding: 12px 0;
  color: $color-text-dark;

  svg {
    color: $color-secondary;
  }

  .freeShipping {
    padding-left: 6px;
    font-size: 12px;
    color: $color-text-dark;
  }
}

.cartFooter {
  padding: 20px 23px 24px 23px;
  box-shadow: 0px -4px 3px -1px rgba(249, 249, 249, 1);
  .total {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    .label {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      color: $color-label;
    }

    .prices {
      width: 200px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      color: $color-label;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;

      .gross {
        font-size: 12px;

        span {
          font-size: 10px;
        }
      }
    }
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    a {
      color: $color-label;
      text-transform: initial;
      text-align: right;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-decoration-line: underline;
      margin-top: 10px;
    }
  }
}

.cartMobile {
  position: relative;

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 17px;
    height: 17px;
    background-color: $color-primary;
    border-radius: 50%;
    font-size: 8px;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
}

.cartsImport {
  display: flex;
  justify-content: center;

  .importWrapper {
    background-color: transparent;
    border: unset;
    display: flex;
    align-items: center;
    height: 50px;
    cursor: pointer;

    label {
      cursor: pointer;
    }

    input {
      display: none;
    }

    @include breakpoint(sm) {
      height: auto;
      padding: 0;
    }
    svg:first-child {
      margin-bottom: 10px;

      @include breakpoint(sm) {
        margin-bottom: 0;
        width: 18px;
        height: 18px;
        top: -3px;
        position: relative;
      }
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-transform: uppercase;
      margin-left: 5px;
      @include breakpoint(sm) {
        font-size: 12px;
      }
      & + svg {
        color: $color-primary;
        align-self: flex-start;
        font-size: 11px;
      }
    }
  }
}

.importTemplate {
  padding: 5px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.badge {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #c1acaa;
  color: $color-white;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  position: absolute;
  left: 8px;
  top: -4px;
}
