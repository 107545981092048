@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  width: 100%;
  margin-top: 12px;
  @include breakpoint(sm) {
    margin-top: 6px;
  }

  .content {
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      > div:first-child {
        order: 1;
      }

      > div:nth-child(2) {
        order: 3;
      }

      > div:nth-child(3) {
        order: 2;
      }

      > div:nth-child(4) {
        order: 4;
      }
    }
  }

  .company {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 16px;

    > div {
      width: calc(50% - 8px);
    }

    .title {
      margin-bottom: 1em;
      font-weight: 700;
    }

    .label {
      color: $color-text-light;
    }
  }

  .summary {
    display: flex;

    > div:first-child {
      margin-right: 40px;
      width: 100%;
    }

    @include breakpoint(sm) {
      padding: 0;
      display: block;
      border-top: 0;
    }
  }
}
