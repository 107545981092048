@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    width: 100%;

    @include breakpoint(sm) {
      row-gap: 9px;
      padding: 5px 0 8px 0;
      flex-wrap: wrap;
      margin: 10px 0 0 0;
    }
  }

  .home {
    font-size: 20px;
    cursor: pointer;
    color: $color-gray-grid-active;

    @include breakpoint(sm) {
      min-height: 24px;
      min-width: 24px;
    }
  }

  .arrow {
    margin-left: 8px;
    margin-right: 8px;

    @include breakpoint(sm) {
      min-height: 16px;
      min-width: 16px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;
    margin-left: 0;

    &:last-of-type {
      .name {
        font-weight: 600;
      }
    }

    &:only-of-type {
      font-weight: 500;
    }

    .name {
      color: $color-breadcrumb;
      cursor: pointer;
      white-space: nowrap;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;

      @include breakpoint(sm) {
        font-size: 12px;
        line-height: 140%;
      }

      &.disabled {
        font-weight: 400;
        color: $color-primary;
        cursor: default;
      }
    }
  }
}
