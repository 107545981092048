@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  background: $color-primary-background;
  color: $color-text-dark;
  display: flex;
  justify-content: center;
  padding: 25px;

  p {
    margin: 0;
  }

  a {
    padding: 4px 8px;
    display: inline-block;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    color: $color-white;
    text-decoration: none;
  }

  @include breakpoint(sm) {
    flex-direction: column;
    text-align: center;
  }
}

.socials {
  display: flex;
  justify-content: center;
}

.icon {
  padding: 5px;
  display: flex;
  align-items: center;
}

.isHomePage {
  flex-wrap: wrap;
  background-color: transparent;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
