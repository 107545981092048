@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  margin-top: 25px;
  margin-bottom: 50px;
  max-width: 450px;
  width: 100%;

  @include breakpoint(sm) {
    padding: 0;
    margin-bottom: 0;
  }
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 35px;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;

  .column {
    &:nth-child(2) {
      text-align: right;
      padding: 0;
    }

    &:last-child {
      text-align: right;
    }
  }

  &:nth-child(2n + 2) {
    background: rgba(239, 234, 232, 0.29);
  }

  @include breakpoint(md) {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.column {
  width: 18%;
  flex-shrink: 0;
  padding: 0 30px;
  color: $color-text;
  white-space: nowrap;

  &:first-child {
    flex: 1;
  }

  @include breakpoint(md) {
    padding: 16px 0;
  }

  @include breakpoint(sm) {
    width: auto;
  }
  &:nth-of-type(3) {
    @include breakpoint(sm) {
      margin-left: 15px;
    }
  }
  &:last-of-type {
    border-right: none;
  }

  .title {
    color: $color-text-dark;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;

    @include breakpoint(md) {
      margin-bottom: 0.5em;
    }

    @include breakpoint(sm) {
      margin-bottom: 0;
    }
  }

  .date {
    display: flex;
    align-items: center;

    .highlight {
      color: $color-secondary;
    }

    svg {
      margin-right: 12px;
      font-size: 20px;
    }
  }

  button {
    border: none;
    background: transparent;
    color: $color-secondary;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    margin-top: 6px;

    @include breakpoint(md) {
      margin-top: 0;
    }
  }
}

.actionsWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.priceWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.discount {
  color: $color-red;
  font-weight: 700;
}

.oldPrice {
  text-decoration: line-through;
}

.totalDiscount {
  text-align: right;
  margin-top: 15px;
  color: $color-red;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-right: 66px;
}

.shippingCost,
.grossValue,
.valueTotalNet,
.valueTotalGross {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  color: $color-text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.oldPrice,
.discount {
  display: block;
  text-align: right;
}

.valueTotalGross {
  color: $color-text;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 0;

  .oldPrice {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
  }

  .discount {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
  }
}

.totalDiscount {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  margin-bottom: 25px;
}

.orderActions {
  margin-top: 50px;

  @media screen and (min-width: 1000px) {
    padding-left: 15px;
  }

  button {
    display: block;
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    border: 1px solid $color-primary !important;

    &:first-child {
      color: $color-primary;
      margin-bottom: 8px;
    }
  }
}
