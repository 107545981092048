@import 'src/theme/color';
@import 'src/theme/breakpoints';
@import 'src/theme/utilities';

.componentWrapper {
  margin: 0 auto;
  padding: 25px 0 0;
  align-items: center;
  color: $color-text;
  flex: 1;
  width: 100%;

  &.isHomePage {
    @include breakpoint(sm) {
      background: transparent;
    }

    .leftActions {
      > span {
        color: $color-white;
      }

      a {
        color: $color-white;
      }
    }
  }

  &.simpleHeader {
    padding: 0;

    .logo {
      display: none;
    }

    .leftActions {
      margin-top: 10px;
    }
  }

  .leftActions {
    a {
      color: $color-text;
      text-decoration: none;
    }
  }

  @include breakpoint(sm) {
    padding: 10px 0 0 !important;
    justify-content: space-between;
    width: 100vw;
    background: $color-white;
    height: $header-height-mobile;
  }

  @include breakpoint(md) {
    padding: 8px;
  }

  .customerInfo {
    display: flex;
    flex: 1;
    color: $color-text-light;
    font-weight: 500;
    font-size: 14px;
  }

  .user {
    display: flex;
    align-items: center;
    margin-right: 20px;

    svg {
      margin-right: 7px;
    }

    &:nth-child(2) {
      padding-left: 20px;
      border-left: 1px solid $color-gray-border;
    }
  }

  .logo {
    @include breakpoint(sm) {
      justify-content: flex-start;

      img {
        height: 95px;
        width: auto !important;
      }
    }

    @include breakpoint(md) {
      img {
        width: 100px;
      }
    }
  }

  .search {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    @include breakpoint(sm) {
      width: 100%;
      margin-top: 10px;
    }
  }

  .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  .leftActions {
    display: flex;
    flex-direction: column;
    width: calc(50% - 75px);
    flex-wrap: wrap;

    > div {
      display: flex;
      align-items: center;
    }

    button {
      padding: 0 10px;
      border: unset;
      background-color: transparent;
      cursor: pointer;
    }

    span {
      padding: 0 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      cursor: pointer;
      color: $filter-color;

      @include breakpoint(sm) {
        font-size: 12px;
      }
    }

    > div:nth-child(2) {
      button {
        padding: 0;
      }

      span {
        padding-left: 15px;
      }
    }
  }

  .languages {
    width: 100%;
    margin-top: 17px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-left: 15px;

    span {
      padding: 0;
      margin-right: 10px;
    }
  }

  .rightActions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(50% - 75px);
    padding-right: 20px;

    @include breakpoint(sm) {
      align-items: baseline;
      padding-top: 5px;
    }

    svg {
      cursor: pointer;
    }

    button {
      border: unset;
      background: transparent;
      padding: 0 5px;

      svg {
        height: 29px;
      }
    }
  }
}

.drawerInner {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px 64px 15px;
  display: flex;

  @include breakpoint(sm) {
    display: block;
    padding: 0 15px 200px 15px;
  }

  > div:first-child {
    button {
      width: 100%;
      padding: 0;

      > div {
        justify-content: space-between;
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }
}

.categories {
  width: 13%;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.slider {
  width: 87%;
  padding: 0 70px 0 30px;
}

.slide {
  padding: 3px;
  text-decoration: none;

  @include breakpoint(sm) {
    display: block;
  }

  img {
    width: 100%;

    @include breakpoint(sm) {
      display: block;
    }
  }

  span {
    display: block;
    margin-top: 15px;
    color: $color-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}

.nextArrow {
  top: 35% !important;
}

.b2bIcon {
  border-radius: 15px;
  border: 2px solid $color-white;
  width: 56px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-left: 20px;
  margin-top: 2px;

  @include breakpoint(sm) {
    margin-left: 10px;
  }

  @include breakpoint(sm) {
    width: 41px;
    height: 23px;
    font-size: 9px;
  }

  &.black {
    color: $color-text;
    border: 2px solid $color-text;
  }
}

.sliderMobile {
  margin-top: 20px;
}
