@import 'src/theme/color';

.wrapperComponent {
  width: 100%;

  > span {
    background-color: $color-gray-border;

    > span {
      background-color: darken($color: $color-gray-border, $amount: 20%);
    }
  }
}
