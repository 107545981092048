@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
}

.header {
  display: flex;
  justify-content: center;
  padding: 14px 0 0;
  margin-bottom: -14px;
  color: $color-label;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.form {
  width: 100%;
  max-width: 304px;
  min-width: 304px;
  margin: 50px auto 27px auto;
  padding: 0 32px;

  input {
    height: 39px;
    border-radius: 0;

    @include breakpoint(sm) {
      font-size: 16px;
    }
  }

  .rememberMe {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-label;
    margin-bottom: 9px;

    > span > span {
      padding: 0;
      padding-bottom: 1px;
      margin-right: 9px;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: -5px;
    }
  }

  .actions {
    margin: 0 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    button {
      height: 34px;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      border-color: $color-primary;
      border-radius: 15px;
      text-transform: initial;

      &:nth-of-type(2) {
        color: $color-primary;

        &:hover {
          color: $color-white;
        }
      }
    }

    .link {
      color: $color-primary;
      text-decoration: none;
    }
  }
}
