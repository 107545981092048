@import 'src/theme/color';

.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  border: 1px solid $color-gray-border;
  padding: 0 16px;
  font-size: 14px;
  background: $color-white;
  margin-right: 10px;

  span {
    text-align: left;
    width: 175px;
    color: $color-text-dark;

    &.placeholder {
      color: $color-text-light;
    }
  }

  svg {
    color: $color-text-light;
    font-size: 20px;
    margin-left: 16px;
  }
}

.pickerWrapper {
  display: flex;

  & > div:first-child {
    padding: 30px 0 30px 20px;

    button {
      display: block;
      font-size: 12px;
      border: unset;
      background-color: transparent;
      margin-bottom: 8px;
      line-height: 15px;
      color: $color-label;
      cursor: pointer;
    }
  }
}
