@import 'src/theme/color';

.wrapperComponent {
  .shoppingListButton {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: auto;
    margin-left: auto;
    cursor: pointer;
    color: $color-text;
    justify-content: flex-end;

    svg {
      color: $color-primary;
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .isButton {
    button {
      width: 100%;
      margin-top: 10px;
      border: 1px solid $color-primary;
      color: $filter-color;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;

      &:hover {
        background-color: inherit;
        color: inherit;
      }

      img {
        margin-left: 10px;
      }
    }
  }
}
