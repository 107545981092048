@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  @media screen and (min-width: 768px) {
    padding: 0 15px;
  }

  .title {
    min-height: 39px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid $color-primary;

    @include breakpoint(sm) {
      margin-top: 4px;
      margin-bottom: 15px;
      padding-bottom: 15px;
    }

    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      color: $color-text-dark;
      margin: 0 0 10px;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 32px;

    .filters {
      padding-top: 10px;
      width: 208px;

      &.isFiltersLoading {
        opacity: 0.4;
        pointer-events: none;
      }

      @media screen and (min-width: 1200px) {
        margin-right: 52px;
      }

      @include breakpoint(sm) {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      > div:not(.selectedFilters) {
        @include breakpoint(sm) {
          padding: 0 32px;
        }
      }

      > div:first-child {
        @include breakpoint(sm) {
          padding-top: 25px;
        }
      }

      .search-category {
        width: 100%;
        background: $color-white;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        color: $color-text;
        font-size: 14px;
        margin-bottom: 12px;
        border-radius: 4px;

        svg {
          margin-right: 8px;
        }

        input {
          flex: 1;
          border: none;
          outline: none;
        }
      }
    }

    .list {
      position: relative;
      flex: 1;

      > div:nth-child(3) {
        > div {
          @include breakpoint(sm) {
            width: 100%;
            padding: 0;
          }
        }
      }

      .searchWrapper {
        position: sticky;
        top: 0;
        width: 100%;
        background-color: $color-white;
        height: 45px;
        display: none;
        z-index: 20;

        @include breakpoint(md) {
          display: block;

          > div:first-child {
            width: 100%;
          }
        }
      }

      .bottomBar {
        background-color: $color-product-bg;
        z-index: 10;

        @include breakpoint(md) {
          // &.isScrollDown {
          //   position: sticky;
          //   top: 45px;
          // }
        }

        @include breakpoint(sm) {
          background-color: $color-white;
        }

        .title {
          position: relative;
          width: 100%;
          padding-bottom: 20px;
          border-bottom: 1px solid $color-gray-border;
          font-family: $font-poppins;
          font-weight: 200;
          font-size: 40px;
          line-height: 110%;
          color: $color-text;

          span {
            font-weight: 300;
            font-size: 24px;
            color: $color-status;
            margin-left: 16px;
          }

          .arrow {
            display: none;
            position: absolute;
            left: 18px;
            margin-top: 4px;
            font-size: 20px;
            font-weight: 800;
          }

          @include breakpoint(md) {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 800;
            font-size: 16px;
            line-height: 140%;
            padding: 24px 18px;
            color: $color-text-dark;

            span {
              font-size: 14px;
              font-weight: 400;
              color: $color-text-light;
              margin-left: 8px;
            }

            .arrow {
              display: block;
            }
          }
        }

        .filterWrapper {
          width: 100%;
          padding: 3px 8px 3px 13px;
          display: none;
          justify-content: space-between;
          align-items: center;
          gap: 6px;

          @include breakpoint(md) {
            display: flex;
          }

          @include breakpoint(sm) {
            padding: 0;
          }

          .filterInfo {
            width: 100%;
            font-size: 13px;
            line-height: 120%;
            color: $color-text-dark;

            :first-child {
              color: $color-text-light;
            }
          }

          .filterButton {
            cursor: pointer;
            width: 100%;
            padding: 13px 16px;
            color: $color-label;
            font-weight: 500;
            gap: 8px;
            display: flex;
            background-color: $color-white;
            border: 1px solid $color-tab-border;
            justify-content: space-between;
            align-items: center;

            @include breakpoint(sm) {
              width: auto;
              border: 0;
              padding: 0;
            }

            svg {
              color: $color-info;

              @include breakpoint(sm) {
                color: $color-label;
                min-width: 20px;
                min-height: 20px;
                transform: rotate(180deg);
              }
            }
          }

          .filterBox {
            text-align: center;
            width: 20px;
            height: 20px;
            background-color: $color-primary;
            color: $color-white;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .selectedFilters {
    padding: 10px 0 0 0;
    margin-bottom: 23px;

    @include breakpoint(sm) {
      padding: 25px 32px;
      margin-bottom: 25px;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;

      @include breakpoint(sm) {
        margin-bottom: 15px;
      }
    }

    + div {
      @include breakpoint(sm) {
        padding-top: 0;
      }
    }
  }

  .filterType {
    color: $color-filter-label;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    margin-bottom: 10px;
    width: 100%;

    @include breakpoint(sm) {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }
  }

  .selectedFilter {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    color: $color-filter-label;
    display: flex;
    align-items: center;
    margin-right: 4px;
    background-color: $color-secondary-border;
    padding: 0 8px;
    border-radius: 8px;

    @include breakpoint(sm) {
      font-size: 12px;
    }

    svg {
      font-size: 16px;
      cursor: pointer;
    }
  }

  .clearAllFilters {
    background-color: transparent;
    font-family: 'Montserrat', sans-serif;
    color: $filter-color;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    border: unset;
    cursor: pointer;

    @include breakpoint(sm) {
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.sortingSelectWrapper {
  @include breakpoint(sm) {
    margin: 0;
  }

  @include breakpoint(sm) {
    > div > div > div:last-child {
      right: 0;
    }
  }

  [class$='-control'] {
    background: transparent;
    padding: 0;
  }
}

.filterModalTitle {
  display: flex;
  align-items: center;
  color: $color-secondary;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;

  svg {
    margin-right: 20px;
    fill: $color-orange;
    font-size: 22px;
  }

  @include breakpoint(sm) {
    padding: 32px;
  }

  > div {
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.filterModalSelectedTitle {
  color: $color-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: uppercase;
}

.breadcrumbsWrapper {
  display: flex;
  justify-content: space-between;

  > div:nth-child(2) div {
    text-transform: uppercase;
  }
}

.actionsTopBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 0 16px;
  color: $color-label;

  @include breakpoint(sm) {
    padding: 0;
    margin-bottom: 30px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  > span {
    @include breakpoint(sm) {
      font-weight: 500;
      margin: 14px 0;
    }
  }

  + div {
    @include breakpoint(sm) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.paginationWrapper {
  padding: 0 10px;
  width: 35%;
  max-width: 500px;
  margin: 80px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint(md) {
    width: 100%;
    max-width: 100%;
  }

  .paginationCount {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $color-label;
    margin-bottom: 14px;
  }

  button {
    margin-top: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }
}

.loaderWrapper {
  margin-left: 20px;
}

.loadingWrapper {
  background-color: rgba($color: $color-gray-background, $alpha: 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.seoBlock {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 20px;
}

.topSection {
  display: flex;
}

.limitWrapper {
  max-width: 95px;
}
