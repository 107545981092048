@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  background: $color-white;
  margin-bottom: 16px;
  border-radius: 15px;
  background-color: #f8f8f8;

  &:not(&.isCart) {
    .buttonWrapper {
      border-top: unset;
    }

    button {
      width: 100%;
    }
  }
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelBold {
  font-size: 16px;
  font-weight: 700;
}

.valueCartNet {
  color: $color-label;

  span {
    color: $color-primary;
    font-family: $font-poppins;
    font-weight: 700;
    font-size: 18px;
    margin-right: 8px;
  }
}

.valueCartGross {
  color: $color-label;

  span {
    color: $color-text-dark;
    font-weight: 500;
    font-size: 16px;
    margin-right: 4px;
  }
}

.valueTotalNet,
.valueTotalGross {
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: $color-text-dark;
  display: flex;
  justify-content: space-between;
}

.valueTotalNet {
  padding-bottom: 12px;

  @include breakpoint(sm) {
    padding-bottom: 10px;
  }
}

.valueTotalGross {
  padding-top: 16px;

  * {
    font-weight: 800;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $color-text;
  }

  @include breakpoint(sm) {
    padding-top: 10px;
  }
}

.value {
  white-space: nowrap;

  @include breakpoint(sm) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.shipmentSection {
  border-top: 1px solid $color-summary-border;
  border-bottom: 1px solid $color-summary-border;
  margin: 12px 0;
  padding: 12px 0;

  > div:first-child {
    margin-bottom: 10px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin-top: 10px;

  @include breakpoint(sm) {
    flex-direction: column;
    margin-top: 0;
  }

  button {
    height: 35px;
    width: 180px;
    font-style: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-transform: uppercase;
    padding: 0;
    margin-bottom: 11px;
    @include breakpoint(sm) {
      width: 100%;
      margin-bottom: 7px;
      font-size: 12px;
    }

    &:first-child {
      color: $filter-color;
      font-weight: 500;
    }

    &:nth-child(2) {
      color: $color-black;
    }

    &:last-child {
      color: $color-white;

      @include breakpoint(sm) {
        border-color: initial;
      }
    }
  }
}

.freeDeliveryMissing,
.freeDelivery {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $color-primary;
  margin-bottom: 50px;

  b {
    text-decoration: underline;
  }

  @include breakpoint(sm) {
    margin-bottom: 25px;
  }
}

.freeDelivery {
  text-transform: uppercase;
}

.invoices {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 14px;
  color: $color-text-light;

  @include breakpoint(sm) {
    font-size: 12px;
  }

  > div {
    margin-top: 10px;
  }
}

.merchantLimit {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: $color-text-light;
  @include breakpoint(sm) {
    margin-bottom: 25px;
  }
}

.missingOrder {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 22px;
  display: block;
  color: $color-red;
  text-decoration: underline;

  @include breakpoint(sm) {
    font-size: 10px;
  }
}

.discountCode {
  margin-bottom: 47px;
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    margin-bottom: 30px;
  }
  > span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    text-transform: uppercase;
    margin-right: 3px;
    color: #a69797;
    white-space: nowrap;

    @include breakpoint(sm) {
      max-width: 80px;
      white-space: break-spaces;
      margin-right: 10px;
    }
  }

  input {
    width: 100%;
    height: 35px;
    border: 1px solid #a69797;
    margin-right: 11px;
    outline: none;
  }

  button {
    height: 35px;
    min-width: 180px;
    width: 180px;
    background-color: $color-primary-background;
    border-color: $color-primary-background;
    color: $color-text-dark;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;

    @include breakpoint(sm) {
      min-width: unset;
      width: auto;
      max-width: 100px;
    }
  }
}

.actionsWrapper {
  @include breakpoint(sm) {
    display: block;
  }
}

.priceWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.discount {
  color: $color-red;
  font-weight: 700;
  font-size: 14px;
  white-space: nowrap;
}

.oldPrice {
  text-decoration: line-through;

  @include breakpoint(sm) {
    font-size: 13px;
  }
}

.totalDiscount {
  color: $color-red;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  @include breakpoint(sm) {
    margin-top: 10px;
    margin-right: 0;
  }
}

.shippingCost,
.grossValue,
.valueTotalNet,
.valueTotalGross {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-right: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  border-bottom: unset;

  @include breakpoint(sm) {
    margin-right: 0;
    font-size: 12px;
  }
}

.valueTotalNet {
  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}

.valueTotalGross {
  @include breakpoint(sm) {
    .discount {
      font-size: 16px;
    }
  }
}

.isCart {
  padding: 55px 40px;
  position: sticky;
  top: 30px;

  @include breakpoint(md) {
    padding: 20px 30px;
  }
}

.isSummary {
  padding: 0;
  margin-bottom: 0;
  flex: 1;
  background-color: transparent;
  max-width: 450px;
  width: 100%;

  .heading {
    max-width: 180px;
    border-bottom: 1px solid $color-gray-border;
    padding-bottom: 7px;
    color: $color-text;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  > div {
    width: 100%;
    padding-top: 0 !important;
    position: sticky;
    top: 30px;
  }

  .actionsWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .oldPrice,
  .discount {
    display: block;
    text-align: right;
  }

  .valueTotalGross {
    margin-bottom: 0;
    .oldPrice {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
    }

    .discount {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .totalDiscount {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    margin-bottom: 25px;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      min-width: 360px;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      text-transform: uppercase;
      color: $color-white;

      @include breakpoint(sm) {
        min-width: unset;
        width: 100%;
      }

      &:first-child {
        background-color: $color-primary-background;
      }

      &:nth-child(3) {
        border: 1px solid $color-primary-background;
        color: $color-text-dark;
        font-weight: 400;
      }

      &:nth-child(3) {
        @include breakpoint(sm) {
          margin-bottom: 0;
        }
      }
    }
  }
}

.isMobileCheckoutTop {
  @include breakpoint(sm) {
    border-bottom: 1px dotted $color-gray-border;
    margin-bottom: 10px;
    padding-bottom: 6px;
  }
  .grossValue,
  .valueTotalNet,
  .valueTotalGross,
  .totalDiscount {
    @include breakpoint(sm) {
      font-size: 10px;
      margin-bottom: 9px;
    }
  }
  .valueTotalGross {
    .oldPrice {
      @include breakpoint(sm) {
        font-size: 12px;
      }
    }

    .discount {
      @include breakpoint(sm) {
        font-size: 14px;
      }
    }
  }
}

.validatingMessage {
  color: $color-red;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
}

.freeShipping,
.shipping {
  color: $filter-color;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    font-size: 12px;
  }

  svg {
    margin-right: 10px;
  }
}

.freeShipping {
  margin-bottom: 20px;
}

.newsletterSubscription {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: $color-text;
  margin-left: -10px;
}
