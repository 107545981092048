@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    column-gap: 3px;
  }
}

.item {
  display: flex;
  align-items: center;
  margin-left: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
  color: $color-gray-grid-active;

  @include breakpoint(sm) {
    margin-left: 0;
    column-gap: 3px;
  }
  span {
    * {
      fill: $color-gray-grid-active;
    }
  }

  &.current {
    span {
      color: $color-primary;
      font-weight: 600;

      * {
        fill: $color-primary;
      }
    }
  }

  > * {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  .icon {
    font-size: 20px;
  }
}

.label {
  margin-left: 12px;
  line-height: 100%;

  @include breakpoint(md) {
    display: none;
  }

  @include breakpoint(sm) {
    font-size: 10px;
    display: block;
    line-height: 140%;
    margin-left: 3px;
  }
}

.arrow {
  margin-left: 12px;
  color: #09193a;
  @include breakpoint(sm) {
    margin-left: 0;
    width: 16px;
  }
}

.clickable {
  cursor: pointer;
}
