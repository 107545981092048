@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  width: 100%;

  > div:first-child {
    @include breakpoint(sm) {
      padding: 0;
      height: auto;
      margin: 10px 0;
    }
    > div {
      @include breakpoint(sm) {
        text-align: left;
      }
    }
    svg {
      @include breakpoint(sm) {
        display: none;
      }
    }
  }
}

.tableWrapper {
  display: block;
  width: 100%;

  table {
    filter: drop-shadow(0px 2px 5px rgba(6, 25, 56, 0.06));
  }

  thead {
    tr {
      th {
        padding: 10px 18px;
        background-color: $color-white;
        border-bottom: 1px solid $color-table-border;
      }
    }
  }

  tbody {
    tr:nth-child(2n) {
      td {
        background-color: $color-gray;
      }
    }
  }

  tr {
    td {
      padding: 10px 18px;

      .deleteButton {
        font-size: 18px;
        color: $color-label;
        cursor: pointer;
      }
    }

    td:first-child {
      border-right: 1px dashed $color-table-border;
    }
  }
}

.titleWrapper {
  width: 100%;
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  justify-content: space-between;
  .text {
    @include breakpoint(sm) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.action {
  display: block;

  @include breakpoint(md) {
    display: none;
  }

  &.mobileAction {
    display: none;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint(md) {
      display: flex;
      margin: 0 16px 16px;
    }
  }

  button {
    padding: 0 12px;

    .label {
      svg {
        font-size: 16px;
        margin-right: 12px;
      }
    }
  }
}

.text {
  display: block;
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: $color-label;
  margin-bottom: 25px;
}

.confirmationModal {
  width: 100%;
  padding: 0 10px;
}

.confirmationModalActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button + button {
    margin-left: 16px;
  }
}

.mobileList {
  .mobileListItem {
    background: $color-white;
    margin-bottom: 8px;

    .itemHeader {
      padding: 16px;
      border-bottom: 1px solid $color-gray-border;
      color: $color-text-dark;

      .name {
        color: $color-primary;
        margin-left: 16px;
      }
    }

    .itemBody {
      padding: 16px;
      border-bottom: 1px solid $color-gray-border;

      .label {
        color: $color-text-light;
        margin-right: 8px;
      }

      > div {
        display: flex;
        padding: 4px 0;

        &:first-child {
          border-bottom: 1px solid $color-gray-border;
          padding-bottom: 8px;
          margin-bottom: 4px;
        }
      }
    }

    .itemFooter {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .deleteButton {
        font-size: 18px;
        color: $color-text-light;
      }

      .link {
        display: inline-flex;
        align-items: center;
        color: $color-secondary;

        svg {
          margin-left: 8px;
        }
      }
    }
  }
}

//button
.link {
  padding: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-shopping-button;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
  @include breakpoint(sm) {
    margin-bottom: 10px;
  }

  .removePositions {
    button {
      display: flex;
      align-items: center;
      border: unset;
      background-color: transparent;
      cursor: pointer;
      @include breakpoint(sm) {
        padding: 0;
      }
      svg {
        margin-right: 7px;
      }
    }
  }
}

.paginationWrapper {
  padding: 0 10px;
  width: 35%;
  max-width: 500px;
  margin: 80px auto 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include breakpoint(md) {
    width: 100%;
    max-width: 100%;
  }
  @include breakpoint(sm) {
    margin: 15px auto 10px;
  }
  .paginationCount {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: $color-label;
    margin-bottom: 14px;
  }

  button {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    padding: 15px 20px;
    height: unset;
  }
}
