@import 'src/theme/color';

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .gridIcon {
    width: 24px;
    height: 24px;
    color: $color-gray-grid;
    margin-left: 10px;
    cursor: pointer;

    &.active {
      color: $color-gray-grid-active;
    }
  }
}
