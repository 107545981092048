@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  width: 100%;
  padding-bottom: 20px;

  .menuWrapper {
    @include breakpoint(md) {
      display: none;
    }
  }
}
