@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  flex-direction: column;

  input {
    height: 38px;
    padding: 0 32px;
    font-size: 12px;
    border-radius: 15px;
    border: 1px solid $color-gray-border;
    background: $color-white;
    color: $color-text-dark;
    outline: none;

    @include breakpoint(sm) {
      padding: 0 10px;
    }

    &:disabled {
      background-color: $color-gray;
      border-color: $color-gray-border;
    }

    &.error {
      border: 1px solid $color-error;
    }
  }
}
