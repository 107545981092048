@import 'src/theme/color';

.wrapperComponent {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 12px 20px;
  background: $color-primary;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: $color-white;
  text-transform: uppercase;

  &.new {
    background: $color-primary;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-red;
  }

  &.kontraktacja {
    background: #525d93;
  }
}
