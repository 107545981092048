@import 'src/theme/color';

.wrapperComponent {
  .radio {
    color: $color-gray-radio;

    &.checked {
      color: $color-primary;
    }
  }
}
