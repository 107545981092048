@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  @include breakpoint(md) {
    flex-direction: column;
  }
  @include breakpoint(sm) {
    display: block;
  }
}

.column {
  @include breakpoint(md) {
    width: 100%;
    border-right: none;
    padding: 0;
  }

  &:last-of-type {
    border-right: none;

    @include breakpoint(md) {
      margin-top: 24px;
      padding-top: 24px;
    }

    @include breakpoint(sm) {
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

.section {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  color: $color-text-dark;
  margin-bottom: 30px;

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $color-text;
    border-bottom: 1px solid $color-gray-border;
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(sm) {
      font-size: 12px;
      height: auto;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      white-space: nowrap;
      @include breakpoint(sm) {
        height: auto;
        font-size: 12px;
      }

      &:last-child {
        > span {
          @include breakpoint(sm) {
            padding: 0;
          }
        }
      }

      svg {
        @include breakpoint(sm) {
          fill: $color-orange;
        }
      }
    }
  }

  button {
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    cursor: pointer;
    width: 150px;

    @include breakpoint(sm) {
      width: 100%;
    }
  }

  .checkboxLabel {
    display: flex;
    color: $color-text-light;
    white-space: nowrap;

    > span {
      color: $color-black;
      white-space: nowrap;
    }

    @include breakpoint(sm) {
      font-size: 12px;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.checkboxWrapper {
  margin-top: 16px;

  checkboxLabel > div {
    font-size: 16px;
    color: $color-text;
  }
}

.receiverOption {
  display: flex;
  align-items: flex-start;
  line-height: 1;
  padding: 4px 0;

  .current {
    color: $color-secondary;
  }
}
.customer {
  @include breakpoint(sm) {
    display: flex;
    column-gap: 15px;
    font-size: 12px;
  }

  > div:first-child {
    margin-bottom: 20px;
  }
}
.isSummary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;
  margin-bottom: 48px;

  @include breakpoint(sm) {
    display: block;
  }

  .column {
    padding: 40px 0 40px 80px;
    flex-basis: unset;
    background-color: $color-gray-background;
    border-radius: 15px;

    @include breakpoint(sm) {
      margin-bottom: 25px;
      padding: 30px 0 30px 40px;
    }

    .title {
      max-width: 190px;
      color: $color-text;
      margin-bottom: 31px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      padding-bottom: 8px;

      @include breakpoint(sm) {
        margin-bottom: 24px;
      }
    }

    .section {
      color: $color-text;
      font-size: 12px;
      font-style: normal;
      line-height: 140%;

      span {
        font-weight: 400;

        &:first-child {
          font-weight: 600;
          max-width: 110px;
          width: 100%;
          display: inline-block;
        }
      }
    }

    .comment {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $color-text;
    }

    .summaryDetail {
      margin-bottom: 7px;
    }

    .customer,
    .receiver {
      display: flex;
      flex-wrap: wrap;
      white-space: nowrap;

      @include breakpoint(sm) {
        column-gap: 15px;

        > div:first-child {
          margin-right: 0 !important;
        }
      }

      > div:first-child {
        margin-right: 20px;
        min-width: 90px;
      }
    }

    .section {
      font-size: 12px;
      position: relative;

      button {
        border: unset;
        background-color: transparent;
        color: $color-primary;
        width: unset;
        margin: 0;
        position: absolute;
        right: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
      }
    }
  }
}

.cashOnDelivery {
  margin-top: 20px;

  span {
    text-decoration: underline;
  }
}

.summaryDetail {
  img {
    margin-right: 8px;
    max-width: 50px;
  }
}

.payment,
.delivery {
  display: flex;
  align-items: center;

  span:first-of-type {
    font-weight: 600 !important;
  }
}

.customerDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: $color-text;

  @include breakpoint(sm) {
    display: block;
  }

  span {
    font-weight: 400;
  }

  .label {
    max-width: 120px;
    width: 100%;
    color: $color-text;
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  > div {
    max-width: 443px;
    width: 100%;
  }
}

.receiverAddress {
  color: $color-text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 34px;

  span {
    padding: 0;
  }

  .checkboxLabel {
    margin-left: 10px;
  }
}

.street {
  display: flex;

  & > div:first-child {
    input {
      width: 100%;
    }
  }

  & > div:nth-child(2) {
    margin-left: 15px;

    .label {
      white-space: nowrap;
      margin-right: 10px;
    }

    & > div {
      input {
        padding: 0 5px;
        text-align: center;
        width: 70px;
      }
    }
  }
}
