@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  padding: 0;
  margin-left: 40px;

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 11px;
    color: $color-text;
    border-bottom: 1px solid $color-gray-border;

    @include breakpoint(sm) {
      font-size: 14px;
    }
  }
  @include breakpoint(sm) {
    padding: 0;
  }
}

.deliveriesWrapper {
  &.isLoading {
    opacity: 0.5;
  }
}

.itemWrapper {
  margin-left: -10px;
  display: inline-block;
  width: 100%;
}

.item {
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  height: 42px;

  @include breakpoint(sm) {
    padding: 0;
  }

  > span {
    > span {
      @include breakpoint(sm) {
        padding: 0;
      }
    }
    svg {
      @include breakpoint(sm) {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  width: auto;

  @include breakpoint(sm) {
    padding: 0;
  }
}

.image {
  display: block;
  max-height: 24px;
  margin-right: 16px;
  margin-left: 10px;
  max-width: 30px;

  @include breakpoint(sm) {
    margin-right: 10px;
    max-width: 39px;
  }
}

.name {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: $color-text;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  &.styleType-HIGHLIGHTED {
    color: $color-secondary;
    font-weight: 700;
  }

  svg {
    font-size: 16px;
    margin-right: 8px;

    @include breakpoint(sm) {
      margin-right: 4px;
    }
  }
}

.missingFreeDelivery {
  color: $color-red;
  @include breakpoint(sm) {
    font-size: 8px;
  }
}

.freeDeliverySuccess {
  color: $color-success;
  @include breakpoint(sm) {
    font-size: 8px;
  }
}

.freeDelivery {
  color: $color-success;
  @include breakpoint(sm) {
    font-size: 8px;
  }
}

.time {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: $color-text;
  @include breakpoint(sm) {
    font-size: 8px;
  }
  svg {
    font-size: 20px;
    margin-right: 8px;
  }
}

.price {
  padding: 10px 0;
  display: flex;
  flex-flow: wrap column;
  margin-left: auto;

  @include breakpoint(sm) {
    padding: 0;
  }
}

.priceGross {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: $color-text;
  display: flex;
  white-space: nowrap;
}

.priceNet {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: $color-text-dark;
  text-align: right;

  span {
    display: inline;
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;
    color: $color-text;
  }
}

.additionalOptions {
  position: relative;

  margin-top: 0;
  & > div {
    margin-bottom: 12px;
  }

  div {
    max-width: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    text-transform: unset;
    color: $color-label;
    @include breakpoint(sm) {
      font-size: 10px;
      margin-bottom: 0;
    }
  }

  > div > div:first-child {
    @include breakpoint(sm) {
      max-width: 126px;
    }
  }

  .additionalOption {
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: $color-text-dark;

    @include breakpoint(sm) {
      font-size: 10px;
      white-space: nowrap;
    }
    > span {
      @include breakpoint(sm) {
        margin-right: 8px;
      }
    }
    span {
      color: $color-primary;

      svg {
        @include breakpoint(sm) {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  span {
    padding: 0;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: $color-text;
  }
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
  @include breakpoint(sm) {
    column-gap: 10px;
    margin-top: 18px;
  }
  button {
    @include breakpoint(sm) {
      width: 100%;
    }
  }
  button:first-child {
    border-color: $color-primary-background;
    color: $color-text-dark;
    font-weight: 500;
  }

  button + button {
    margin-left: 12px;
    @include breakpoint(sm) {
      margin: 0;
    }
  }
}

.attachments {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    display: block;
  }
}

.attachment {
  margin-left: 30px;

  &:first-child {
    margin-bottom: 20px;
  }

  div {
    word-wrap: break-word;
  }

  span {
    white-space: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  p {
    margin: 0;
    display: flex;
    align-items: center;

    @include breakpoint(sm) {
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  button {
    cursor: pointer;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #c5bebe;
    background-color: transparent;
    padding: 5px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #717378;

    &:hover {
      background-color: $color-primary;
      border: 1px solid $color-primary;
      color: $color-white;
    }
  }

  &:nth-child(2) {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.radio {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    width: unset;
    height: unset;
  }

  & > div {
    width: 42px;
    height: 42px;
  }
}

.deleteAttachment {
  svg {
    margin-left: 5px;
    font-size: 12px;
    color: $color-red;
  }
}
