@import 'src/theme/color';
@import 'src/theme/breakpoints';

#about {
  padding: 53px 100px;
  max-width: 1200px;
  margin: 0 auto;

  @include breakpoint(sm) {
    padding: 0;

    h2 {
      display: block !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}

.home-page {
  a {
    text-decoration: none;
  }

  img {
    @include breakpoint(sm) {
      display: block;
      width: 100%;
      height: unset;
    }
  }

  video {
    @include breakpoint(lg) {
      width: 100vw;
      margin-left: -15px;
    }

    @include breakpoint(lg) {
      margin-left: -25px;
    }
  }

  > div:last-child {
    @include breakpoint(sm) {
      width: 100vw;
      margin-left: -15px;
    }
  }
}

.modal {
  max-width: 675px;
}
