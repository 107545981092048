@import 'src/theme/color';
.container {
  height: 100%;
  .control {
    height: 100%;
    min-height: 38px;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    border-color: $color-table-border;
    background-color: $color-white;
    flex-wrap: nowrap !important;
    padding: 0 18px;
    border-radius: 16px;

    svg {
      transform: rotateX(0);
    }

    &.controlOpen {
      background-color: $color-gray-background;
      svg {
        transform: rotateX(180deg);
      }
    }
    &:hover {
      border-color: $color-table-border;
    }
  }

  .option {
    font-size: 16;
    padding: 8px 16px;

    color: $color-label;
    background-color: $color-white;
    margin: 0;

    &.optionSelected {
      color: $color-secondary;
      background-color: $color-gray-background;
      margin: 8px 0;
    }

    &:hover {
      color: $color-secondary;
    }
  }

  .indicatorSeparator {
    display: none;
  }

  .indicatorsContainer {
    padding: 0 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dropdownIndicator {
    color: $color-secondary;
    background-color: $color-white;
    &:hover {
      color: $color-secondary;
    }
  }
  .valueContainer {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 0;
    margin-right: 0;
  }
  .singleValue {
    color: $color-text;
    margin-left: 0;
    margin-right: 0;
    font-weight: 400;
  }
  .menu {
    margin-bottom: 0;
    margin-top: 0;
    width: max-content;
    min-width: 100%;
    box-shadow: none;
    border: 1px solid $color-dropdown;
    border-radius: 0;
  }
  .menuList {
    padding: 0;
  }
}
