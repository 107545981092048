@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  align-items: center;

  .authButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: $color-text;
    cursor: pointer;
    border-right: 1px solid $color-gray-border;

    &.logged {
      padding: 0;
    }

    @include breakpoint(sm) {
      margin-left: 0;
    }

    svg {
      margin-right: 10px;
      color: $color-primary;
      font-size: 20px;
      margin-left: 12px;

      @include breakpoint(sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.paper {
  overflow: visible !important;
  margin-left: 17px;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $color-white;
    position: absolute;
    top: -12px;
    right: 30px;
  }
}
