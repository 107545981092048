@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import 'src/theme/color';
@import 'src/theme/utilities';
@import 'src/theme/breakpoints';

html {
  min-height: 100vh;
}

body {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

#root {
  min-height: 100vh;
}

main {
  padding-top: 200px;
  min-height: calc(100vh - 81px);

  @include breakpoint(sm) {
    padding-inline: 15px;
    padding-top: 110px;
  }
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.rdp-day_selected,
.rdp-day_range_start {
  border-radius: 100% !important;
}

.thin {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}

.MuiSwitch-root {
  .MuiSwitch-track {
    background-color: $color-primary;
  }
}

.StylePath-Pages-Home-components-Banner .carousel__dot--selected {
  font-weight: 800 !important;
  color: $color-slider-dot !important;
}

.customSlick {
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
}

.yarl__slide_image {
  -webkit-backface-visibility: unset !important;
  -webkit-transform: unset !important;
}

.yarl__carousel_with_slides {
  column-gap: unset !important;
}

.rdp-button:not(.rdp-nav_button) {
  width: 25px !important;
  height: 25px !important;
  border-radius: 0 !important;
  margin-right: 0 !important;
  padding: 10px !important;
}

.rdp-cell {
  width: 25px !important;
  height: 25px !important;
}

.rdp-day_selected {
  background-color: #d4c1bf !important;
  color: $color-text-dark !important;
}

.rdp-day_range_start,
.rdp-day_range_end {
  background-color: #d4c1bf !important;
}

.rdp-button:hover:not([aria-disabled='true']) {
  background-color: #d4c1bf !important;
}

.rdp-head_cell {
  font-weight: 400 !important;
  color: #a1a4ab !important;
}

.rdp-caption_label {
  font-size: 13px !important;
  font-weight: 400 !important;
  text-transform: capitalize;
}

.SnackbarItem-message {
  white-space: pre-wrap;
}

.scrollableContent {
  position: relative;
}

.htmlBlock {
  p {
    margin: 0;
  }

  img {
    display: block;
  }
}
