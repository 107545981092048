@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  margin-top: 30px;
  @include breakpoint(sm) {
    margin-top: 15px;
  }
  > div {
    @include breakpoint(sm) {
      margin-bottom: 15px;
    }

    &:last-of-type {
      @include breakpoint(sm) {
        margin-bottom: 0;
      }
    }
  }

  & > div:not(.extended):nth-child(n + 2) {
    table {
      thead {
        th {
          visibility: hidden;
          height: 0;
          padding: 0;

          span {
            display: none;
          }
        }
      }
    }
  }

  .extended {
    border-bottom: 1px solid rgba($color: $color-table-border, $alpha: 0.6);

    table {
      width: 100%;

      tr th:first-child {
        min-width: 140px;
      }

      td:nth-child(4n + 3),
      td:nth-child(4n + 4) {
        background-color: rgba(239, 234, 232, 0.29);
      }
    }

    &:nth-child(n + 2) {
      table {
        thead {
          th {
            visibility: hidden;
            height: 0;
            padding: 0;

            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .isContracting {
    table {
      th {
        min-width: unset !important;

        &:first-child {
          width: 65px;
        }

        &:nth-child(2) {
          width: 100%;
        }
      }

      tr {
        th,
        td {
          &:nth-child(2) {
            padding-right: 0;
          }
        }
      }
    }
  }

  table {
    margin: 0 auto;
    th:last-child {
      width: 130px;
    }

    @include breakpoint(sm) {
      width: 100%;
      margin-top: 30px;

      th:first-child {
        width: 40px;
        max-width: 40px;
      }

      th:last-child {
        width: unset;
      }
    }

    tr {
      th,
      td {
        &:first-child,
        &:nth-child(2) {
          padding-right: 10px;
        }
      }

      th {
        @include breakpoint(sm) {
          width: 50%;

          span {
            white-space: nowrap;
          }
        }

        &:first-child,
        &:nth-child(2) {
          padding-left: 0;
        }
      }
    }

    &:first-child {
      tr:last-child {
        td {
          border-bottom: transparent;
          @include breakpoint(sm) {
            padding-bottom: 0;
          }
        }
      }
    }
    &:first-child {
      tr:first-child {
        td {
          @include breakpoint(sm) {
            padding-top: 0;
          }
        }
      }
    }

    th {
      background-color: transparent;
      color: $color-text;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;

      @include breakpoint(sm) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }

    td {
      padding: 5px 0;

      @include breakpoint(sm) {
        font-size: 10px;
        padding: 2px 0 0;
      }
    }

    & + table {
      thead th {
        padding: 0;

        span {
          display: none;
        }
      }
    }

    thead th {
      @include breakpoint(sm) {
        vertical-align: bottom;
      }
      &:nth-child(4) {
        @include breakpoint(sm) {
          position: relative;
        }
        span > span {
          @include breakpoint(sm) {
            width: 100%;
            font-weight: 600;
            position: absolute;
            left: -50%;
            transform: translate(50%, 0);
            top: -15px;
            display: flex;
            justify-content: center;
            white-space: nowrap;
          }

          @media screen and (max-width: 590px) {
            top: 0;
          }

          @media screen and (max-width: 510px) {
            white-space: unset;
            top: -15px;
          }
        }
      }
      &:nth-child(6) {
        @include breakpoint(sm) {
          min-width: 80px;
        }
      }
    }

    tbody td {
      &:nth-child(6) {
        input {
          @include breakpoint(sm) {
            margin: 0;
            flex: unset;
            width: 100%;
            font-size: 10px;
            border: 0;
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      tr {
        td:first-child {
          padding-right: 10px;
        }
      }
    }
  }

  .cell {
    height: 35px;
    padding: 0px 20px;
    border: 1px solid $color-gray-border;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @include breakpoint(sm) {
      width: 200px;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .counter {
    @include breakpoint(sm) {
      margin-bottom: 10px;
    }

    @media screen and (max-width: 500px) {
      > div {
        width: 100%;
      }
    }
  }

  .priceExtended {
    > div {
      margin-right: 0;
    }
  }

  .counter {
    button {
      width: 36px;
      height: 31px;

      @include breakpoint(sm) {
        width: 36px;
        height: 36px;
      }
    }

    input {
      width: 64px;

      @include breakpoint(sm) {
        width: 32px;
        height: 32px;
        min-width: 25px;
        max-width: 36px;
      }
    }
  }

  .collapseTitle {
    font-size: 10px;
    font-weight: 600;
    span {
      text-transform: none;
      font-weight: 400;
    }
  }

  .availabilityTitle {
    position: absolute;
    transform: translate(-50%, -30px);
    width: 100px;
  }

  .availability {
    > div {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-bottom: 1px solid rgba(224, 224, 224, 0.6);
      }
    }
  }

  .extendedQty {
    button {
      background: transparent;
    }
  }

  .promoPrice {
    span {
      display: block;
      font-size: 13px;

      &:first-child {
        text-decoration: line-through;
      }

      &:nth-child(2) {
        color: $color-red;
      }

      @include breakpoint(sm) {
        font-size: 10px;
      }
    }

    br {
      display: none;
    }

    @include breakpoint(sm) {
      flex-direction: column;
    }
  }

  .sizingTableLabel {
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-decoration-line: underline;
    cursor: pointer;
  }
}
