@import 'src/theme/color';
@import 'src/theme/breakpoints';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  height: 100%;
  .control {
    height: 100%;
    border-radius: 0;
    border: unset;

    @include breakpoint(md) {
      height: 100%;
      padding: 0;
    }

    @include breakpoint(sm) {
      padding: 0 10px;
    }

    box-shadow: none;
    flex-wrap: nowrap !important;

    svg {
      transform: rotateX(0);
      transition: all 100ms ease;
    }
    &.controlOpen {
      svg {
        transform: rotateX(180deg);
      }
    }
    &:hover {
      border-color: inherit;
    }
  }

  .option {
    border-bottom: 0;
    padding: 0 18px;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 8px;
    color: $color-text;
    font-weight: 400;
    padding-top: 3px;
    padding-bottom: 3px;

    @include breakpoint(md) {
      padding: 0 10px;
    }

    &.optionSelected {
      font-weight: 600;
    }

    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: $color-secondary-border;
      background-color: #faf9f8;
    }
  }

  .indicatorSeparator {
    display: none;
  }
  .indicatorsContainer {
    display: flex;
    flex-flow: wrap row;
    align-items: center;
  }
  .dropdownIndicator {
    display: flex;
    flex-flow: wrap row;
    align-items: center;
    padding: 0;
  }
  .valueContainer {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    padding-left: 0;
  }
  input {
    position: absolute;
    left: 0;
  }

  .singleValue {
    font-size: 14px;
    color: $color-label;
    font-weight: 500;
  }
  .placeholder {
    font-size: 14px;
    color: $color-label;
    font-weight: 500;
    margin: 0;
  }

  .menu {
    top: calc(100% - 1px);
    right: 0;
    margin: 0;
    width: max-content;
    min-width: 188px;
    padding: 10px 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.1), 0px -2px 10px rgba(6, 25, 56, 0.1);
    border-radius: 0px 0px 2px 2px;
    overflow: hidden;
    z-index: 999;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    * {
      text-transform: initial !important;
    }

    @include breakpoint(md) {
      right: initial;
    }
  }

  .menuList {
    padding: 0;
    overflow: auto;
    max-height: 200;
  }
}
