@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  width: 100%;
  background: $color-white;

  .summary-blocks {
    display: flex;
    .summary-entities {
      min-height: 350px;
      padding: 24px 0 0 34px;

      @include breakpoint(md) {
        padding: 24px 0 0 16px;
        order: 2;
      }

      > div {
        justify-content: start;
      }
      > div > div {
        padding: 0 0 24px 0;
        border-right: unset;
      }
    }

    .summary-status {
      background: $color-gray-background;

      @include breakpoint(md) {
        width: 100%;
        height: auto;
        margin-left: 16px;
        padding-left: 0;
        padding-right: 16px;
        justify-content: space-between;
        background: $color-white;
        border-bottom: 1px dashed #cccfd5;
        order: 1;
        padding-top: 30px;
        padding-bottom: 30px;

        .box {
          &:first-child {
            order: 1;
          }
          &:nth-child(2) {
            order: 3;
          }
          &:last-child {
            order: 2;
          }
        }
      }

      display: flex;
      flex-wrap: wrap;

      height: 100%;
      padding-left: 50px;

      .status-small {
        height: 14px;
      }
    }
  }
}

.box {
  border-top: 1px solid $color-gray-border;
  padding: 20px 0 20px 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $color-text-dark;

  &:nth-child(n + 5) {
    border-bottom: 1px solid $color-gray-border;
    @include breakpoint(sm) {
      border-bottom: unset;
    }
  }

  @include breakpoint(sm) {
    padding: 10px 0;
    border-top: 1px dashed $color-gray-border;
    font-size: 12px;
  }
}

.payment {
  .pay {
    display: flex;
    align-items: center;

    button {
      margin-left: 10px;
      border: unset;
      background-color: transparent;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      color: $color-red;
      cursor: pointer;
    }
  }
}

.popoverContent {
  padding: 20px 25px;

  h3 {
    margin: 10px 0;
  }

  h4 {
    margin: 10px 0;
  }
}

.inner {
  border-left: 1px dashed $color-gray-border;
  padding-left: 45px;
  height: 100%;

  @include breakpoint(sm) {
    padding-left: 0;
    border-left: 0;
  }

  > div:nth-child(3) {
    @include breakpoint(sm) {
      padding-top: 5px;
    }
  }
}

.collection {
  font-size: 12px !important;
  margin-bottom: 6px !important;

  @include breakpoint(sm) {
    border-top: 1px dashed $color-gray-border;
    padding-top: 6px;
  }
}

.blocks {
  display: flex;
  flex-flow: nowrap row;
  width: 100%;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include breakpoint(sm) {
    display: block;
    margin-bottom: 18px;
  }
}

.title {
  color: $color-text;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;

  @include breakpoint(sm) {
    justify-content: space-between;
    margin-bottom: 5px;
  }
  span:nth-child(1) {
    @include breakpoint(sm) {
      color: $color-text;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 0px;
    }
  }
  span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: $color-text-dark;
    margin-left: 20px;

    @include breakpoint(sm) {
      font-size: 10px;
    }

    svg {
      margin-left: 5px;
    }
  }

  @include breakpoint(md) {
    color: $color-text-dark;
    font-weight: 500;
  }

  &.mainTitle {
    color: $color-primary;
    margin-top: 20px;
    padding-left: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-gray-border;
    @include breakpoint(sm) {
      display: none;
    }
  }
}

.boxTitle {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-dark;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }

  @include breakpoint(sm) {
    font-size: 14px;
    margin: 5px 0 10px;
    line-height: 1;
    align-items: center;
  }
}

.changeBtn {
  color: $color-text;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  text-decoration-line: underline;
  cursor: pointer;
  border: unset;
  padding: 0;
  background-color: transparent;
  width: initial !important;

  @include breakpoint(sm) {
    &:not(&.bottom) {
      margin-right: -20px;
    }
  }

  &.bottom {
    position: absolute;
    bottom: 40px;
    right: 40px;

    @include breakpoint(sm) {
      bottom: 20px;
      right: 20px;
    }
  }

  @include breakpoint(sm) {
    margin-right: 0;
  }
}

.label {
  color: $color-label;
}

.name {
  font-weight: 700;
  padding-right: 16px;
  margin-bottom: 10px;
  font-size: 16px;

  @include breakpoint(md) {
    color: $color-text-light;
    font-weight: 400;
  }

  &.small {
    font-size: 14px;
    margin-bottom: unset;
  }
}

.list {
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-left: 34px;

  @include breakpoint(md) {
    margin-left: 16px;
    flex-direction: column;

    .name {
      color: $color-text-dark;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  padding: 20px 0;
  border-top: 1px dashed $color-gray-border;
}

.history {
  display: flex;
  flex-wrap: wrap;
}

.event {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  margin-right: 16px;

  @include breakpoint(md) {
    gap: 15px;
  }

  .date {
    width: 150px;
    @include breakpoint(md) {
      width: auto;
    }

    span {
      color: $color-label;
    }
  }
}

.value {
  font-size: 12px;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    max-width: 100px;
  }
}

.statusWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
  color: $color-label;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @include breakpoint(sm) {
    display: block;
  }
}

.hold {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: -12px;

  @include breakpoint(sm) {
    margin-right: -20px;
    font-size: 10px;
  }

  .switchBase {
    padding: 14px 14px 14px 14px;
  }

  .switchThumb {
    height: 10px;
    width: 10px;
    background-color: $color-white;
  }

  .trackThumb {
    width: 28px;
    height: 14px;
    background-color: #dbdbdb !important;
    opacity: 1 !important;
  }

  .switchChecked {
    &.switchBase {
      transform: translateX(14px);
    }

    & + .trackThumb {
      background-color: $color-red !important;
    }
  }
}

.attachment {
  width: 50%;

  div {
    word-wrap: break-word;
  }

  span {
    white-space: nowrap;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  p {
    display: flex;
    align-items: center;
  }

  button {
    cursor: pointer;
    margin-left: 10px;
    border-radius: 4px;
    border: 1px solid #c5bebe;
    background-color: transparent;
    padding: 5px 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #717378;

    &:hover {
      background-color: $color-primary;
    }
  }
}

.commentEdit {
  textarea {
    width: 100%;

    @include breakpoint(sm) {
      border: 1px solid $color-primary-background;
    }
  }

  .actions {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    border-top: 1px solid $color-gray-background-dark;

    button {
      width: 160px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.download {
  button {
    color: $color-text-dark;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
    border: unset;
    display: flex;
    align-items: center;

    &:disabled {
      opacity: 0.5;
    }

    > div {
      height: unset;
      margin-left: 30px;
    }
  }
}

a.link {
  border: unset;
  margin-left: 0;
  padding-left: 0;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #717378;
  text-decoration: none;
}

.deliveryPoint {
  font-size: 12px;
  margin-top: 5px;
}

.trackingNumber {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 700;

  span {
    font-weight: 400;
    text-decoration: underline;
  }
}

.topActions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.downloadLabel {
  color: $color-text;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  svg {
    margin-bottom: -3px;
    margin-right: 10px;
  }
}

.downloadSection {
  button {
    color: $filter-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration-line: underline;
    padding: 0;
    cursor: pointer;
    border: unset;
    background-color: transparent;
    margin-left: 10px;
  }
}

.columnsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 32px;
  margin-bottom: 48px;

  @include breakpoint(sm) {
    display: block;
  }
}

.column {
  position: relative;
  width: 100%;
  border-right: none;
  padding: 40px 40px 40px 80px;
  flex-basis: unset;
  background-color: $color-gray-background;
  border-radius: 15px;

  @include breakpoint(sm) {
    margin-bottom: 25px;
    padding: 30px 40px 30px 40px;
  }

  &:last-of-type {
    border-right: none;

    @include breakpoint(md) {
      margin-top: 24px;
      padding-top: 24px;
    }

    @include breakpoint(sm) {
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

.section {
  color: $color-text-dark;
  margin-bottom: 30px;
  color: $color-text;
  font-size: 12px;
  font-style: normal;
  line-height: 140%;

  span {
    font-weight: 400;

    &:first-child {
      font-weight: 600;
      max-width: 110px;
      width: 100%;
      display: inline-block;
    }
  }

  .title {
    border-bottom: 1px solid $color-gray-border;
    max-width: 190px;
    color: $color-text;
    margin-bottom: 31px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    padding-bottom: 8px;

    @include breakpoint(sm) {
      margin-bottom: 24px;
    }

    @include breakpoint(sm) {
      margin-bottom: 24px;
    }

    @include breakpoint(sm) {
      font-size: 12px;
      height: auto;
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      white-space: nowrap;
      @include breakpoint(sm) {
        height: auto;
        font-size: 12px;
      }

      &:last-child {
        > span {
          @include breakpoint(sm) {
            padding: 0;
          }
        }
      }

      svg {
        @include breakpoint(sm) {
          fill: $color-orange;
        }
      }
    }
  }

  .comment {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $color-text;

    > div:first-child {
      display: flex;
      justify-content: space-between;
    }
  }

  .summaryDetail {
    margin-bottom: 7px;
  }

  button {
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    cursor: pointer;
    width: 150px;

    @include breakpoint(sm) {
      width: 100%;
    }
  }

  .label {
    display: flex;
    color: $color-text-light;
    white-space: nowrap;

    > span {
      color: $color-black;
      white-space: nowrap;
    }

    @include breakpoint(sm) {
      font-size: 12px;
    }
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.paymentDeliveryWrapper {
  img {
    max-width: 50px;
    margin-right: 8px;
  }

  .summaryDetail {
    display: flex;
    align-items: center;
  }

  .name {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 0;
  }

  .paymentStatus {
    display: flex;
    align-items: center;

    button {
      width: unset;
      display: inline;
      max-width: unset;
      padding: 0;
      background-color: transparent;
    }

    img {
      margin-left: 10px;
    }
  }

  .trackingNumber {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration-line: underline;
  }
}

.backIcon {
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  a {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
