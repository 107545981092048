@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
  min-height: 35vh;

  table {
    thead {
      th {
        background: transparent;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $color-text;
        white-space: nowrap;
        border-bottom: 1px solid $color-summary-border;
      }
    }

    tbody {
      tr:nth-child(2n) {
        td {
          background: rgba(239, 234, 232, 0.29);
        }
      }

      td {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $color-text;
      }
    }
  }
}

.id {
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(sm) {
    margin-left: 0 !important;
    justify-content: flex-end;
  }
}

.payment {
  @include breakpoint(sm) {
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: transparent;
      border: unset;
      padding: 0;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-left: 20px;
      text-decoration: underline;
    }
  }

  .paid {
    color: $color-affirmative;
  }

  .unpaid {
    color: $color-red;
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 12px;
    }
  }

  & > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      text-decoration-line: underline;
      background-color: transparent;
      border: unset;
      margin-top: 8px;
      cursor: pointer;
    }
  }
}

.hold {
  @include breakpoint(sm) {
    > span {
      padding: 0;
      height: 15px;
      width: 28px;
    }
  }

  .switchBase {
    padding: 14px 14px 14px 14px;

    @include breakpoint(sm) {
      padding: 2px;
    }
  }

  .switchThumb {
    height: 10px;
    width: 10px;
    background-color: $color-white;
  }

  .trackThumb {
    width: 28px;
    height: 14px;
    background-color: #dbdbdb !important;
    opacity: 1 !important;
  }

  .switchChecked {
    &.switchBase {
      transform: translateX(14px);
    }

    & + .trackThumb {
      background-color: #c67878 !important;
    }
  }
}

.filtersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 25px 0 35px 0;

  .filter:nth-child(2),
  .filter:nth-child(4) {
    margin-left: 15px;

    @include breakpoint(sm) {
      margin-left: 0;
    }
  }

  .filter {
    @include breakpoint(sm) {
      margin: 0;
    }
    &:nth-child(1),
    &:nth-child(2) {
      @include breakpoint(sm) {
        width: 100%;
      }
    }
    div > form,
    > button {
      @include breakpoint(sm) {
        margin: 0 0 15px 0;
        width: 100%;
      }
    }
    div > span + svg {
      fill: $color-primary;
    }

    & > div > div > div {
      padding: 0;

      & > div {
        white-space: nowrap;
      }
    }
  }
  @include breakpoint(md) {
    align-items: flex-start;
    padding: 0 16px 8px;
  }

  > * {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
      @include breakpoint(sm) {
        width: 100%;
      }
    }

    @include breakpoint(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.userSelectWrapper {
  width: 300px;

  > div {
    width: 100%;
  }
}

.mobileList {
  .mobileListItem {
    background: $color-white;
    margin-bottom: 8px;

    .line {
      border-bottom: 1px solid $color-gray-border;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-of-type {
        border-bottom: none;
      }

      .label {
        color: $color-text-light;
        margin-right: 8px;
      }

      .right {
        text-align: right;

        .label {
          margin-right: 0;
        }
      }

      .primary {
        color: $color-primary;
        margin-left: 16px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .highlight {
        color: $color-text-dark;
        font-weight: 700;
        font-size: 16px;
      }

      .link {
        color: $color-secondary;
        font-size: 12px;
        display: inline-flex;
        align-items: center;

        svg {
          margin-left: 8px;
        }
      }
    }
  }
}

.link {
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: $color-text-dark;

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50px;
    margin-left: -15px;
  }

  svg {
    @include breakpoint(sm) {
      margin-left: 9px;
    }
  }
}

.exportButton {
  border: none;
  background: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: $color-text-dark;
  text-decoration: underline;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;

  svg {
    margin-left: 10px;
    width: 22px;

    @include breakpoint(sm) {
      margin-left: 0;
      width: 15px;
    }
  }

  &:hover {
    color: $color-secondary;
  }
}

.exportLoader {
  > div {
    height: auto;
  }
}

.activateIconWrapper {
  display: flex;
  align-items: center;
  margin-left: 14px;
  max-width: 22px;
  width: 100%;

  button {
    padding: 0;
    border: unset;
    background: unset;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include breakpoint(sm) {
      width: 18px;
      height: 16px;
    }
  }

  &.isEditMode {
    svg path {
      fill: $color-red;
    }
  }
}

.clearFilters {
  background-color: transparent;
  border: unset;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $color-red;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
}

.trackingNumber {
  font-size: 13px;
  text-decoration: underline;

  a {
    color: $color-text-dark;
  }
}

.popoverContent {
  padding: 20px 25px;
}

.rightFilters {
  max-width: 560px;
  width: 100%;
  justify-content: space-between;
}
