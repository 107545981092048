@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  padding: 0;
  width: 100%;

  @include breakpoint(sm) {
    margin-bottom: 10px;
  }

  @include breakpoint(md) {
    margin-bottom: 60px;
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 11px;
    color: $color-text;
    border-bottom: 1px solid $color-gray-border;

    @include breakpoint(sm) {
      font-size: 14px;
    }
  }

  @include breakpoint(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(sm) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0;
  }
}

.paymentsWrapper {
  margin-left: -10px;
  @include breakpoint(sm) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 13px;
    row-gap: 10px;
  }
}

.content {
  display: flex;
  flex-flow: wrap column;
  flex: 1;
  align-items: center;
  padding: 12px 0 8px 0;
  @include breakpoint(sm) {
    padding: 22px 0 10px 0;
  }
}

.name {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: $color-text;
  width: 100%;
  text-align: left;
}

.commission,
.cashDelivery {
  span {
    display: block;
    text-align: right;
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-left: 10px;
  }

  button {
    border: unset;
    background-color: transparent;
    color: $color-primary;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    cursor: pointer;
  }
}

.cashDeliveryValue {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin-top: 10px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.icon {
  display: block;
  max-height: 32px;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  height: 42px;

  &.isLoading {
    opacity: 0.5;
  }
}
