@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
}

.accountWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;

  @include breakpoint(sm) {
    margin-bottom: 15px;
    display: block;
  }

  .column {
    width: 50%;
    padding: 0 70px;

    @include breakpoint(md) {
      padding: 0 40px;
    }

    @include breakpoint(sm) {
      width: 100%;
      padding: 0 20px;
    }
  }

  .section {
    width: 100%;
    margin-bottom: 28px;

    @include breakpoint(sm) {
      max-width: unset;
      width: 100%;
      padding: 0;
    }

    > .title {
      @include breakpoint(sm) {
        color: $filter-color;
        margin-bottom: 8px;
      }
    }
  }

  .invoice {
    display: flex;
    justify-content: space-between;

    @include breakpoint(sm) {
      margin-bottom: 10px;
      border-bottom: 1px dashed $color-gray-border;
    }
  }

  .contact {
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-decoration-line: underline;

    a {
      color: $color-text;

      &:hover {
        color: $color-text;
      }
    }

    @include breakpoint(sm) {
      margin-top: 4px;
      font-size: 8px;
    }
  }

  .sectionMiddle {
    width: 100%;
    padding: 0 55px;
    max-width: 435px;
    border-left: 1px dashed $color-gray-border;
    border-right: 1px dashed $color-gray-border;

    @include breakpoint(lg) {
      width: 33.33%;
      padding: 0;
      border: unset;
    }

    @include breakpoint(sm) {
      width: 100%;
      padding: 0;
      border: unset;
      max-width: unset;
    }
  }

  input {
    width: 100%;
  }

  button {
    margin-top: 21px;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;

    @include breakpoint(sm) {
      margin-top: 12px;
    }
  }
}

.halfWidth {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.login {
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  text-align: center;
  font-size: 22px;

  svg {
    position: absolute;
    top: 6px;
    right: 0;
    cursor: pointer;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;

  @include breakpoint(sm) {
    font-size: 14px;
    margin-bottom: 10px;
  }

  &.secondary {
    margin-top: 32px;
  }
}

.info {
  background-color: $color-tab-bg;
  padding: 30px 35px 20px 35px;
  color: $color-text-light;
  margin-top: 5px;

  @include breakpoint(sm) {
    margin-top: 15px;
    text-align: center;
  }

  b {
    font-style: normal;
    font-size: 16px;
    line-height: 140%;

    @include breakpoint(sm) {
      font-size: 14px;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: $color-text-light;
    text-decoration: unset;
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;

    @include breakpoint(sm) {
      font-size: 10px;
    }
  }

  div {
    @include breakpoint(sm) {
      display: flex;
      justify-content: center;
    }

    > div {
      display: flex;
      align-items: center;
      margin-top: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;

      @include breakpoint(sm) {
        font-size: 10px;
        margin-top: 7px;

        &:first-child {
          margin-right: 10px;
        }
      }

      svg {
        margin-right: 7px;

        path {
          stroke: $color-primary;
        }
      }
    }
  }
}

.label {
  color: $color-text;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  max-width: 100px;
  display: block;
  width: 100%;
}

.captcha {
  margin-top: 25px;
}

.consent {
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-left: -15px;

  .label {
    color: $filter-color;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    max-width: unset;
    display: inline;
  }
}

.error {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 140%;
  color: $color-red;
}

.selectWrapper {
  border: 1px solid $color-gray-border;
}

.customerDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: $color-text;

  @include breakpoint(sm) {
    display: block;
  }

  > div {
    max-width: 443px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: unset;
    }
  }
}

.title {
  color: $color-text;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  &.isRegisterForm * {
    font-size: 15px;
    font-weight: 500;
  }

  &.newsletter,
  &.newsletter * {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 70px;

  > div {
    width: 30%;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      width: 15%;
    }
  }

  @include breakpoint(md) {
    padding: 0 40px;
  }

  @include breakpoint(sm) {
    padding: 0 20px;

    > div {
      width: 100%;
      display: block;
    }
  }

  button {
    width: 100%;
  }
}

.changeButton {
  & > div {
    display: flex;
    justify-content: flex-end;
  }

  button {
    width: unset;
    color: $color-text;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-decoration-line: underline;
    border: unset;
    background-color: unset;
    cursor: pointer;
  }
}

.street {
  display: flex;

  & > div:nth-child(2) {
    margin-left: 15px;

    .label {
      white-space: nowrap;
      margin-right: 10px;
    }

    & > div {
      input {
        padding: 0 5px;
        text-align: center;
        width: 70px;
      }
    }
  }
}

.heading {
  padding: 0 70px;
  margin-bottom: 40px;

  @include breakpoint(md) {
    padding: 0 40px;
  }

  @include breakpoint(sm) {
    padding: 0 20px;
  }

  h2 {
    color: $color-text;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    text-transform: initial;
  }

  @include breakpoint(sm) {
    margin-bottom: 25px;
  }
}
