@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: block;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;
  border-bottom: 1px solid $color-gray-border;
  padding-bottom: 8px;
}

.settingsSelector {
  margin-bottom: 32px;

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
  }
}

.attributes {
  margin-bottom: 32px;
}

.options {
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include breakpoint(sm) {
    flex-direction: column;
    gap: 16px;
  }

  > div {
    width: calc(50% - 10px);
    @include breakpoint(sm) {
      width: 100%;
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
  }

  .separator,
  .period,
  .start_date {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;

    input {
      width: 50px;
      text-align: center;
      outline: none;
    }
  }
}

.name {
  margin-bottom: 16px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-gray-border;
  padding-top: 16px;

  @include breakpoint(md) {
    display: block;
  }

  .linkWrapper {
    display: flex;
    align-items: center;
    color: $color-text-light;

    @include breakpoint(md) {
      margin-bottom: 16px;
    }

    .linkBox {
      border: 1px solid $color-gray-border;
      height: 30px;
      width: 500px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(lg) {
        width: 400px;
      }

      @include breakpoint(md) {
        width: 100%;
        height: auto;
        padding: 8px;
      }

      a {
        color: $color-primary;
        text-decoration: none;
      }
    }

    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include breakpoint(sm) {
      display: block;
    }

    button {
      margin-left: 16px;
      white-space: nowrap;

      @include breakpoint(md) {
        width: 100%;
      }

      @include breakpoint(sm) {
        margin-left: 0;
        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  }
}

.error {
  font-size: 12px;
  color: $color-error;
  width: 100%;
}
