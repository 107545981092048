@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 130px;
  height: 35px;
  border: 1px solid $color-gray-border;

  @include breakpoint(sm) {
    width: 123px;
  }

  &.disabled {
    background-color: lighten($color: $color-gray-border, $amount: 10%);

    * {
      cursor: not-allowed;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

.input {
  width: calc(100% - 88px);
  height: 30px;
  border: 0;
  flex: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: $color-text;

  @include breakpoint(sm) {
    width: 49px;
    margin: 0;
    font-size: 12px;
    height: 20px;
  }
  &:focus {
    outline: none;
  }
}

.loaderWrapper {
  position: absolute;
  left: 50%;
}

.button {
  color: $color-text;
  display: flex;
  align-items: center;
  border: unset;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    color: $color-text-light;
  }
}
