@import 'src/theme/color';

.componentWrapper {
  display: flex;
  flex-flow: wrap row;
  align-items: center;
  width: auto;

  svg {
    font-size: 20px;
    margin-right: 6px;
  }

  .label {
    color: $color-label;
    font-size: 11px;
  }
}

.line {
  .stock {
    font-size: 14px;
  }
}

.low-stock {
  > div:first-child {
    background-color: $color-primary-background;
  }

  &-line {
    > div:first-child {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

.stock {
  padding: 3px 0px;
  border: 1px solid $color-primary-background;
  margin-right: 7px;
  width: 22px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: $color-label;

  &:last-child {
    margin-right: 0;
  }
}

.available {
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
}

.active {
  background-color: $color-primary;
  color: $color-white;
  border: 1px solid $color-primary;
}

.name {
  display: inline;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: $color-label;
  strong {
    font-weight: 500;
  }
}
