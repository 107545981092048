@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  span {
    color: $color-label;
  }

  .unitConverterMessage {
    display: block;
    font-size: 10px;
  }

  table {
    height: 1px;

    th {
      background-color: transparent;

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: $color-text-dark;
        text-transform: uppercase;
      }
    }

    tr {
      td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        vertical-align: top;
      }
    }
  }
}

.productThumb {
  @include breakpoint(sm) {
    img {
      width: 60px;
    }
  }
}

.productInfo {
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  .productName,
  .productSize,
  .productQty {
    display: block;
    color: $color-label;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 14px;
  }

  .productSize,
  .productQty {
    font-style: normal;
    color: $filter-color;
  }
}

.priceWrapper {
  display: flex;
  align-items: baseline;
  color: $filter-color;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  &.totalPrice {
    justify-content: flex-end;
    color: $color-text;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.totalPriceNetWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.mobileWrapper {
  padding: 16px 17px 20px;
  margin-bottom: 8px;
  background-color: white;

  .mobileRow {
    font-size: 13px;
    line-height: 120%;
    color: $color-text;
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      padding: 16px 0 0;
    }

    .title {
      color: $color-text-dark;
    }

    .minor {
      font-size: 12px;
    }

    .textPadding {
      padding-bottom: 3px;
    }

    .small {
      font-size: 10px;
      font-weight: 300;
    }

    .label {
      color: $color-text-light;
      margin-bottom: auto;
      padding-right: 8px;
    }

    .productWrapper {
      display: flex;
      margin-left: 11px;
      width: 100%;
      justify-content: space-between;

      .unitWrapper {
        display: flex;

        .unitData {
          text-align: center;
        }
      }
    }

    .priceWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .borderPrice {
        height: auto;
        width: 1px;
        margin: 0 10px;
        background-color: $color-table-border;
      }

      .unitPrice {
        div {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
      .totalPrice {
        display: flex;
      }
    }

    img {
      width: 65px;
      height: 65px;
    }

    &:not(:last-child) {
      border-bottom: 1px dashed $color-table-border;
    }
  }
}

.discount {
  color: $color-red;
  font-weight: 700;
}

.oldPrice,
.discount {
  @include breakpoint(sm) {
    line-height: 140% !important;
  }
}

.oldPrice {
  text-decoration: line-through;
}

.showMore {
  color: $color-primary;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;

  svg {
    color: $color-text-dark;
  }
}

.tableWrapper {
  display: block;
  width: 100%;

  .link {
    color: $color-primary;
    text-decoration: none;
  }
}

.counter {
  display: inline-block;
  width: 16px;
}

.noWrapLabel {
  white-space: nowrap;

  &.right {
    text-align: right;

    span {
      display: inline-block;
      text-align: left;
      width: 34px;
    }
  }

  &.last {
    margin-right: 10px;
  }
}

.actionsBarWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    border: unset;
    background-color: transparent;
    display: flex;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;

    svg {
      margin-right: 7px;
    }
  }
}

.actionsBar {
  background: $color-white;
  width: 100%;
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > svg {
    margin: 0 24px;
  }
}

.cartSelectorWrapper {
  width: 300px;
}

.exportWrapper {
  margin-left: auto;

  > button.ghost {
    border: 1px solid $color-gray-border;
    padding: 10px;

    svg {
      margin-right: 12px;
    }
  }
}

.actionsWrapper {
  button {
    border: unset;
    background-color: transparent;
    cursor: pointer;
  }
}

.priceNet {
  .discount {
    margin-left: 5px;
  }
}
