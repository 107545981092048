@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  max-width: 211px;
  width: 100%;
  height: 45px;
  border: 1px solid $color-gray-border;
  z-index: 99;
  margin-right: 20px;
  visibility: hidden;

  &.isHomePage {
    border-color: transparent;
  }

  &.isVisible {
    visibility: visible;
  }

  @include breakpoint(sm) {
    width: 100%;
    background-color: $color-white;
    max-width: initial;
  }

  > form {
    width: 100%;
    height: 100%;

    > input {
      width: 100%;
      display: block;
      margin: 0;
      height: 100%;
      padding-left: 15px;
      outline: none;
      border: none;
      flex: 1;
      color: $color-text-light;
      font-size: 14px;
      z-index: 5;

      @include breakpoint(sm) {
        font-size: 16px;
      }

      &::placeholder {
        color: $color-text-light;
        font-size: 14px;

        @include breakpoint(sm) {
          font-size: 16px;
        }
      }
    }
  }

  .icon {
    width: 57px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text;
    border-left: 1px solid $color-gray-border;
    cursor: pointer;
    z-index: 5;
    &:first-child {
      padding: 10px;
    }

    @include breakpoint(md) {
      order: 1;
    }

    &.hide {
      display: none;
    }
  }
}
