@import 'src/theme/color';
@import 'src/theme/breakpoints';

.icon {
  display: block;
}

.wrapperComponent {
  display: block;
  margin-bottom: 8px;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(sm) {
      justify-content: flex-start;
    }
  }

  .MuiPaper-root {
    border-radius: 4px;
    padding: 2px;
    border: 1px solid;

    @include breakpoint(md) {
      min-width: 0;
      margin: 0 8px;
    }

    @include breakpoint(sm) {
      margin: 0;
      padding: 10px;
    }

    &.message-success {
      background-color: $color-alert-success-bg;
      border-color: $color-alert-success-border;
      color: $color-alert-success-border;
    }
    &.message-warning {
      background-color: $color-alert-warning-bg;
      border-color: $color-alert-warning-border;
      color: $color-alert-warning-border;
    }
    &.message-error {
      background-color: #fdf7f7;
      border-color: $color-alert-error-border;
      color: $color-alert-error-border;
    }

    & div:first-of-type {
      display: flex;
      align-self: flex-start;
      align-items: center;
      margin: 6px 0 0 20px;
      color: inherit;

      @include breakpoint(md) {
        align-self: flex-start;
      }

      @include breakpoint(sm) {
        padding: 0;
        margin-top: 3px;
        margin-left: 0;
        margin-right: 18px;
      }
    }

    & div:last-of-type {
      display: flex;
      margin: 0;
      padding: 10px;
      font-size: 14px;
      font-weight: 400;
      color: $color-text-dark;

      @include breakpoint(md) {
        font-size: 10px;
        line-height: 120%;
      }
      @include breakpoint(sm) {
        display: block;
        padding: 0;
      }
      > span {
        margin: 0 15px;

        @include breakpoint(sm) {
          font-size: 10px;
          line-height: 140%;
        }
      }

      > a {
        @include breakpoint(sm) {
          padding: 2px 0;
          margin-top: 5px;
          font-size: 10px;
        }
      }

      .alert-close {
        margin: 0 0 0 20px;

        :hover {
          cursor: pointer;
        }
      }
    }
  }
}
