@import 'src/theme/color';

.wrapperComponent {
  width: 100%;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .list {
    .selected {
      width: 30px;
      height: 30px;
      background-color: $color-primary;
    }
  }
}
