@import 'src/theme/color';

.content {
  max-width: 400px;
  margin: 0 auto;
}

.actionWrapper {
  margin-top: 60px;
  display: flex;
  justify-content: center;

  button {
    width: 100%;
    max-width: 170px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 600;

    &:first-child {
      margin-right: 10px;
    }
  }
}

.dynamicSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-size: 12px;
    font-weight: 600;
  }

  & > div {
    border: 1px solid #c5bebe;
    border-radius: 4px;
    width: 190px;

    & > div > div {
      min-height: 24px !important;
      padding-left: 0 !important;
      padding-right: 5px !important;

      div {
        font-size: 11px !important;
      }
    }
  }
}

.dynamicDecimal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-size: 12px;
    font-weight: 600;
  }

  & > input {
    border: 1px solid #c5bebe;
    border-radius: 4px;
    width: 190px;
    height: 25px;
    font-size: 11px;
    color: $color-label;
    font-weight: 500;
    padding: 0 10px;
  }
}
