@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  padding: 30px 0 50px 0;

  @include breakpoint(sm) {
    padding: 0;
  }

  .contentWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @include breakpoint(lg) {
      display: block;
    }
  }

  .content {
    flex: 1;
    background-color: $color-gray-background;
    border-radius: 15px;
    padding: 40px 0;

    @include breakpoint(sm) {
      padding: 10px 0 40px 0;
    }
  }

  .heading {
    padding: 0 70px;
    margin-bottom: 40px;

    @include breakpoint(md) {
      padding: 0 40px;
    }

    @include breakpoint(sm) {
      padding: 0 20px;
    }

    h1 {
      color: $color-text;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      text-transform: initial;
    }

    @include breakpoint(sm) {
      margin-bottom: 25px;
    }
  }
}
