@import 'src/theme/color';

.wrapperComponent {
  .checkbox {
    color: $color-gray-checkbox;
    &.checked {
      color: $color-primary;
    }
    &.indeterminate {
      color: $color-primary;
    }
  }

  &.isSecondary {
    .checkbox {
      &.checked {
        color: #a69797;
      }
    }
  }
}
