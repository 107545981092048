@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  table {
    thead {
      th {
        background: transparent;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: $color-text-dark;
        white-space: nowrap;
        border-bottom: 1px solid $color-summary-border;
      }
    }

    tbody {
      tr:nth-child(2n) {
        td {
          background: rgba(239, 234, 232, 0.29);
        }
      }

      td {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $color-text-dark;
      }
    }
  }
}

.filtersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  @include breakpoint(md) {
    display: block;
    padding: 0 16px 8px;
    text-align: end;

    > :first-child {
      margin-bottom: 16px;
    }
  }
}

.colored {
  &.primary {
    color: $color-primary;
  }

  &.secondary {
    color: $color-secondary;
  }

  &.success {
    color: $color-success;
  }

  &.error {
    color: $color-error;
  }
}

.editButton {
  border: none;
  background: transparent;
  color: $color-secondary;
  font-size: 18px;
  cursor: pointer;
}
