@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  .newsletterTop {
    background-color: $color-secondary-background;
    padding: 78px 0;

    h2 {
      color: $color-text;
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    h3 {
      color: $color-text;
      text-align: center;
      font-family: 'Playfair Display';
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 0;
    }

    .text {
      color: $color-text;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: n ormal;
      margin-bottom: 40px;
    }

    @include breakpoint(sm) {
      margin: 0 -25px 15px -25px;
      width: calc(100% + 50px);
      padding: 50px 20px 45px 20px;
    }
  }

  .newsletter {
    display: flex;
    justify-content: center;

    @include breakpoint(sm) {
      flex-direction: column;
    }

    input {
      border: 2px solid $color-newsletter;
      min-width: 358px;
      margin-right: 10px;
      color: $filter-color;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 0px;

      @include breakpoint(sm) {
        margin-bottom: 10px;
        min-width: unset;
        width: 100%;
      }
    }

    button {
      background-color: $color-newsletter;
      border-color: $color-newsletter;
      color: $color-text;
      color: #555;
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: 0px;
      line-height: 0px;
    }
  }
}
