@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  display: flex;
  align-items: center;
  padding-right: 20px;
  position: relative;

  @include breakpoint(sm) {
    display: block;
    padding-right: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
  .main-image {
    width: 100%;
    margin-right: 16px;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  .thumb {
    cursor: pointer;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid $color-white;

    @include breakpoint(sm) {
      background-position: center;
      width: 80px !important;
      height: 121px;
    }
  }
}

.arrow {
  width: unset;
  height: unset;
  right: 50%;
  z-index: 9;

  @include breakpoint(sm) {
    right: unset;
  }

  &.arrowPrev {
    top: 30px;
    transform: translate(50%, 0);

    @include breakpoint(sm) {
      top: 50%;
      left: 0;
      transform: translate(0) rotate(-90deg);
    }
  }

  &.arrowNext {
    top: calc(100% - 50px);
    transform: translate(50%, 0) rotate(180deg);

    @include breakpoint(sm) {
      top: 50%;
      right: 0;
      transform: translate(0) rotate(90deg);
    }
  }

  &::before {
    display: none;
  }
}

.horizontalSlider {
  width: calc(100% - 96px);
}

.verticalSlider {
  width: 96px;
  max-height: 470px;
  margin-left: 4px;
}

.gallerySlide {
  padding: 0 4px;
  cursor: pointer;

  @include breakpoint(sm) {
    padding: 0;
  }

  img {
    width: 100%;
  }
}

.mobileSlider {
  margin: 0 auto;
}

.slickWrapper {
  max-width: 120px;
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    max-width: unset;
    display: block;
    margin: 0 auto;
  }
  button {
    z-index: 999;

    &::before {
      display: none;
    }

    &:first-of-type {
      top: -36px;
      left: 50%;
      transform: translate(-50%, 0) !important;

      @include breakpoint(sm) {
        top: 50%;
        left: 30px;
        transform: translate(-50%, 0) rotate(270deg) !important;
      }

      + div {
        @include breakpoint(sm) {
          max-width: 200px;
          margin: 0 auto;
        }

        > div {
          @include breakpoint(sm) {
            display: flex;
            column-gap: 17px;
          }
          > div {
            @include breakpoint(sm) {
              width: 80px !important;
              height: 121px;
            }
          }
        }
      }
    }

    &:last-of-type {
      top: calc(100% + 15px);
      left: 50%;
      transform: translate(-50%, 0) !important;

      @include breakpoint(sm) {
        top: 50%;
        left: unset;
        right: 20px;
        transform: translate(-50%, 0) rotate(270deg) !important;
      }
    }

    svg {
      color: $color-primary;
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  img {
    margin: 0 auto;
  }
}

.labels {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.label {
  display: inline-block;
  margin: 0 4px 4px 0;
  padding: 6px 10px;
  background: $color-primary;
  font-size: 12px;
  font-weight: 700;
  color: $color-white;

  &.new {
    background: $color-accent;
  }

  &.bestseller {
    background: $color-bestseller;
  }

  &.promotion {
    background: $color-violet;
  }
}
