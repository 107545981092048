@import 'src/theme/color';
@import 'src/theme/breakpoints';

.componentWrapper {
  background-color: $color-primary-background;
  height: 50px;
  color: $color-text;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  width: 100%;
  z-index: 999;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  font-size: 11px;

  @include breakpoint(sm) {
    font-size: 10px;
    text-align: center;
  }
}

.languagesWrapper {
  display: flex;
  align-items: center;
  margin-top: 15px;

  @include breakpoint(sm) {
    margin-top: 0;
  }

  button > div {
    display: flex;
    align-items: center;
  }
}
