@import 'src/theme/color';

.wrapperComponent {
  .cart {
    border: 1px solid $color-primary;
    background: transparent;
    color: $color-primary;
    border-radius: 15px;
    cursor: pointer;
  }

  button {
    border-radius: 15px;
    width: 100%;
  }

  .dropdown {
    margin-right: 8px;

    svg {
      color: $color-primary;
    }
  }

  &.large {
    .cart {
      color: $color-white;

      svg {
        margin-left: 16px;
        font-size: 20px;
      }
    }

    .dropdown {
      svg {
        color: $color-white;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
