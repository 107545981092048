@import 'src/theme/breakpoints';
@import 'src/theme/color';

.componentWrapper {
  margin-top: 20px;
  @include breakpoint(sm) {
    margin-top: 0;
  }

  iframe {
    max-width: unset !important;
  }

  .pocztex {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      width: 35px;
      height: 55px;
      background-color: $color-white;
    }
  }
}

.widget {
  visibility: hidden;
  height: 1px;
  overflow: hidden;
}

.inpostWidget {
  > iframe {
    width: 100%;
    height: 520px;
    border: unset;
  }
}

.dpdWidget {
  > iframe {
    width: 100%;
    height: 800px;
    border: unset;
    margin: 0 auto;
  }
}

.visible {
  visibility: visible;
  height: auto;
  overflow: auto;
}
