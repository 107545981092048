@import 'src/theme/color';
@import 'src/theme/font';
@import 'src/theme/breakpoints';

.productDetails {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  > div:first-child {
    width: 100%;
  }

  @include breakpoint(sm) {
    margin-top: 10px;
  }

  @include breakpoint(md) {
    padding: 0;
  }
}

//h1
.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  color: $color-text;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 5px;
}

.labels {
  margin-bottom: 40px;
  &,
  + div {
    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }
}

.producer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: block;
  color: $color-gray-border;

  a {
    color: inherit;
    text-decoration: none;
  }
}

//ul
.list {
  display: flex;
  flex-flow: wrap row;
  list-style: none;
  margin: 0;
  padding: 0;
}

//li
.listItem {
  position: relative;
  display: block;
  width: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $color-text;

  &:not(:last-child) {
    padding-right: 15px;
    margin-right: 14px;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 5px);
      right: 0;
      width: 1px;
      height: 10px;
      background-color: $color-text;
    }
  }

  strong {
    display: inline;
    font-weight: 500;

    a {
      text-decoration: none;
      color: $color-primary;
    }
  }
}

.description {
  width: 100%;

  @include breakpoint(sm) {
    margin-bottom: 0;
  }
}

//span
.label {
  display: block;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: $color-text;
}

.text {
  padding-top: 10px;
  color: $color-black;
  font-size: 12px;
  font-style: normal;
  font-weight: 275;
  line-height: 140%;
  border-top: 1px solid $color-gray-border;
  margin-top: 10px;

  br {
    display: none;
  }

  ul {
    padding: 0;
    list-style-type: none;
    margin: 0;

    li {
      font-size: 14px;
      line-height: 140%;

      &::before {
        content: '·';
        margin: 0 5px;
        font-weight: 500;
      }

      &::marker {
        display: none;
      }

      div {
        display: inline;
      }
    }
  }
}

.priceWrapper {
  margin-bottom: 14px;

  @include breakpoint(sm) {
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
  }

  span {
    display: block;
    font-style: normal;
    line-height: 140%;
    color: $color-price;
  }

  .nettoPrice {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text;
    margin-bottom: 5px;
  }

  .oldPrice {
    text-decoration: line-through;
    font-weight: 500;
    font-size: 14px;
  }

  .discountPrice {
    color: $color-violet;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    @include breakpoint(sm) {
      margin-left: 5px;
    }
  }

  .eurPrice {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text;
    margin-bottom: 5px;
  }

  .retailPrice {
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    color: $color-text;
  }
}

.loginButton {
  text-decoration: none;
  color: $color-primary;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;

  small {
    font-weight: 400;
  }
}

.block {
  display: flex;
  flex-flow: wrap row;
  width: 100%;
}

.promotion {
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: $color-violet;
  font-size: 14px;
  line-height: 20px;

  svg {
    font-size: 20px;
    margin-right: 4px;
  }

  strong {
    font-weight: 700;
    margin-left: 4px;
  }

  a {
    font-size: 12px;
    color: $color-black;
    text-decoration: none;
    margin-left: 8px;
  }
}
.collapseTitle {
  @include breakpoint(sm) {
    font-size: 16px;
  }
}

.sizingTable {
  margin-bottom: 10px;

  span {
    color: $color-text;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    text-decoration-line: underline;
    cursor: pointer;
    padding: 10px 0;
  }
}

.download,
.favourites {
  color: $color-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid $color-gray-border;
  cursor: pointer;
}

.download {
  border: unset;
  background-color: transparent;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }

  > div {
    height: unset;
    margin-right: 25px;
  }
}

.favourites {
  padding-right: 15px;
}

.colapseWrapper {
  padding: 10px 0;

  > div {
    margin-bottom: 0;

    > div:first-child {
      padding-right: 18px;
    }
  }
}

.collapseTitle {
  color: $color-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  text-transform: capitalize;
}

.detail {
  display: flex;
  align-items: center;
  color: $color-text;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid $color-gray-border;
  text-decoration: unset;

  a {
    color: $color-text;
    text-decoration: none;
  }
}

.sizingTableTitle {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.deliveryInfo {
  color: #a88871;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
