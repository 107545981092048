@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  @include breakpoint(sm) {
    margin-top: 15px;
  }
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: $color-text-dark;
  }
}

.formGroup {
  display: grid;
  column-gap: 12px;

  @include breakpoint(sm) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 6px;
  }
}

.nameGroup {
  grid-template-columns: auto auto;

  @include breakpoint(sm) {
    grid-template-columns: 1fr;
  }
}

.streetGroup {
  grid-template-columns: calc(50% - 6px) calc(25% - 9px) calc(25% - 9px);
  @include breakpoint(sm) {
    margin-top: 8px;
  }
  > div {
    &:nth-child(2) {
      @include breakpoint(sm) {
        width: calc(50% - 3px);
      }
    }
    &:last-child {
      @include breakpoint(sm) {
        width: calc(50% - 3px);
      }
    }
  }
}

.townGroup {
  grid-template-columns: auto auto auto;
  > div {
    @include breakpoint(sm) {
      width: calc(50% - 3px);
    }
  }
}

.contactGroup {
  grid-template-columns: calc(33.33% - 6px) calc(66.66% - 6px);
}

.formElement {
  width: 100%;
  &.formElementHalfWidth {
    width: calc(50% - 10px);
  }

  margin-bottom: 12px;
}

.actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  &.isEditMode {
    justify-content: center;

    button {
      max-width: 160px;
      width: 100%;
    }
  }

  @include breakpoint(sm) {
    margin-top: 16px;
  }

  button:first-child {
    border-color: $color-primary-background;
    color: $color-text-dark;
    font-weight: 500;
  }

  button + button {
    margin-left: 12px;
  }
}

.error {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 140%;
  color: $color-red;
}

.customerDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: $color-text;

  @include breakpoint(sm) {
    display: block;
  }

  span {
    font-weight: 400;
  }

  .label {
    max-width: 120px;
    width: 100%;
    color: $color-text;
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  > div:nth-child(2) {
    max-width: 443px;
    width: 100%;
  }
}

.street {
  display: flex;

  & > div:first-child {
    input {
      width: 100%;
    }
  }

  & > div:nth-child(2) {
    margin-left: 15px;

    .label {
      white-space: nowrap;
      margin-right: 10px;
    }

    & > div {
      input {
        padding: 0 5px;
        text-align: center;
        width: 70px;
      }
    }
  }
}
