@import 'src/theme/color';
@import 'src/theme/breakpoints';

.wrapperComponent {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @include breakpoint(sm) {
    display: block;
    margin-top: 0;
    padding: 0 15px;
    margin-bottom: 15px;
  }

  + div {
    @include breakpoint(sm) {
      margin-top: 15px;
    }
  }
}

.menuItem {
  display: flex;
  flex-flow: wrap column;
  align-items: center;
  padding: 0 0 16px;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: $color-text-dark;
  padding: 10px 0;
  white-space: nowrap;

  @include breakpoint(sm) {
    border-left: unset;
    border-bottom: 1px dashed $color-summary-border;
    padding: 10px 0 10px 20px;
    min-height: 47px;
  }

  &:first-child {
    border-left: unset;
    padding-left: 0;

    .content {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: $color-text-light;
    }
  }

  .arrow {
    margin-left: auto;
  }

  &.open {
    .content {
      color: $color-text-light;
    }
  }
}

.separator {
  width: 1px;
  height: 40px;
  border-left: 1px dashed $color-summary-border;

  @include breakpoint(sm) {
    display: none;
  }

  + .empty {
    display: none;
  }

  &.emptySeparator {
    display: none;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.link {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: $color-text-dark;

  .arrow {
    margin-left: auto;
  }

  text-decoration: none;
  color: inherit;

  &.active {
    color: $color-secondary;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  width: 22px;
  margin-right: 8px;
  font-size: 20px;
}

.subItemsWrapper {
  display: flex;
  flex-flow: wrap column;
  width: 100%;
  padding-left: 30px;
}

// import { Link as NavLink } from 'components/controls';
.subItem {
  display: flex;
  padding: 12px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-decoration: none;
  color: $color-text;

  &.current {
    color: $color-secondary;
  }
}
